import React from 'react';
import { List } from 'react-admin';
import PaginationMod from "./PaginationMod";

export const ListMod = (props) => {
    const sort = props.sort ? props.sort : { field: 'id', order: 'DESC' };

    return <List {...props}
                 perPage={50}
                 pagination={<PaginationMod />}
                 sort={sort}
                 exporter={false}
            />;
}

export default ListMod;
