import {
    BooleanInput,
    Create,
    FormTab,
    Link,
    required,
    TabbedForm,
    TextInput,
    useCreate,
    useNotify,
    useRedirect
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {httpGet, httpGetOneWithParams} from "../../../Api/api";
import AsyncSelect from 'react-select/async';
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";

export const CreateProduct = () => {
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const [imageSrc, setImageSrc] = useState();
    const [categoriesList, setCategoriesList] = useState([]);
    const initialRef = useRef(true);
    const [formData] = useState({
        name: '',
        categories: [],
        description: '',
        image: '',
        published: false,
        subscription: false
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        httpGet('products/general/category-list').then(res => {
            if (res.data.length) {
                setCategoriesList(res.data)
            }
        });
    }, [])

    const productCreate = () => {
        dispatch(setLoadStatus(true));
        if (document.querySelector('.async-select').classList.contains('error')) {
            document.querySelector('.async-select').classList.remove('error');
        }

        if (!formData.name.length || !formData.categories.length) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }
            if (!formData.categories.length) {
                notify('Please, choose category.', { type: 'warning' });
                document.querySelector('.async-select').classList.add('error');
            }
            dispatch(setLoadStatus(false));
            return;
        }

        create('products/general', {data: {
                name: formData.name,
                categories: formData.categories,
                description: formData.description,
                icon: formData.image,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                published: formData.published ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
            }}, {
            onSuccess: (res) => {
                redirect(`/products/general/${res.id}/en`);
                notify('Product created');
                dispatch(setLoadStatus(false));
            },
            onError: (res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            }
        });
    };

    const selectCategory = (e) => {
        if (document.querySelector('.async-select').classList.contains('error')) {
            document.querySelector('.async-select').classList.remove('error');
        }
        formData.categories = e.map((item) => {
            return item.value;
        });
    }

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                <Link to="/products/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={productCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const loadOptions = (
        inputValue
    ) => {
        return new Promise((resolve, reject) => {
            return httpGetOneWithParams('products/general/category-list', {label: inputValue}).then((res) => {
                if (res.data.length) {
                    resolve(res.data)
                } else {
                    resolve([])
                }
            }, reject)

        })
    };

    const uploadImage = (imageFile) => {
        if(!imageFile) {
            return;
        }

        const mimeType = imageFile.type;
        const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!mimetypes.includes(mimeType)) {
            notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
            return;
        }

        formData.image = imageFile;

        const reader = new FileReader();

        reader.onload = () => {
            setImageSrc(reader.result);
        }

        reader.readAsDataURL(imageFile);
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">New Product</h2>
            <Create title="Create Product">
                <TabbedForm className="tabbedForm" record={formData} toolbar={<></>}>
                    <FormTab label="General">
                        <div className="tabbedFormWrap">
                            <TextInput className="formFields name" label="Name" name="name" source="formData.name" fullWidth id="name" validate={required()} onChange={(e) => {nameChange(e)}} multiline/>
                            <p className="required-text">Required</p>
                            <div className="async-select-wrap">
                                <AsyncSelect className="async-select" isMulti placeholder="Category" defaultOptions={categoriesList} loadOptions={loadOptions} onChange={(e) => {selectCategory(e)}}/>
                                <p className="required-text">Required</p>
                            </div>
                            <TextInput className="formFields" label="Description" name="description" source="formData.description" id="description" multiline fullWidth onChange={(e) => {formData.description = e.target.value}} />
                            <div className="form-field">
                                <span className="fieldTitle">Icon</span>
                                <div>
                                    <div className="imageWrap">
                                        <img
                                            className="image"
                                            src={imageSrc}
                                            alt=""
                                        />
                                        <p style={{display: 'none'}}>Required</p>
                                    </div>
                                    <label className="imageLabel" htmlFor="imageInputCreate"></label>
                                    <input
                                        type="file"
                                        id="imageInputCreate"
                                        className="fileInput"
                                        accept="image/png, image/jpeg, image/jpg"
                                        onChange={(e) => uploadImage(e.currentTarget.files[0])}
                                    />
                                    <label htmlFor="imageInputCreate" className="uploadButton">
                                        {formData.image ? 'Update Image' : 'Choose an Image'}
                                    </label>
                                </div>
                            </div>
                            <BooleanInput label="Published" name="published" source="formData.published" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.published = e.target.checked}}/>
                            <BooleanInput label="Subscription Required" name="subscription" source="formData.subscription" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.subscription = e.target.checked}}/>
                        </div>
                        <Save />
                    </FormTab>
                    <FormTab label="EN" path="en" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="PT" path="pt" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="DE" path="de" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                </TabbedForm>
            </Create>
        </>
    );
}