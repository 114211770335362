import CustomLayout from './App/Component/Layouts/CustomLayout';
import authProvider from './App/Auth/authProvider';
import {createTheme} from '@material-ui/core/styles';
import {defaultTheme, Login} from "react-admin";
import {Admin, Resource} from 'react-admin';
import dataProvider from './App/Api/api';
import * as React from "react";
import {CustomerList} from "./App/Pages/Customer/list";
import {CustomerShow} from "./App/Pages/Customer/show";
import {DailyDareGeneralList} from "./App/Pages/DailyDare/General/list";
import {DailyDareGeneralCreate} from "./App/Pages/DailyDare/General/create";
import {DailyDareGeneralEdit} from "./App/Pages/DailyDare/General/edit";
import {CreateCategory} from "./App/Pages/Content/categories/createCategory";
import {EditCategory} from "./App/Pages/Content/categories/editCategory";
import {CreateProduct} from "./App/Pages/Content/products/createProduct";
import {EditProduct} from "./App/Pages/Content/products/editProduct";
import Categories from "./App/Pages/Content/categories/categories";
import Products from "./App/Pages/Content/products/products";
import Files from "./App/Pages/Content/files/files";
import {CreateFile} from "./App/Pages/Content/files/createFile";
import {EditFile} from "./App/Pages/Content/files/editFile";
import './Styles/fonts.css';
import './Styles/style.css';
import {CustomerCreate} from "./App/Pages/Customer/create";
import {ReportList} from "./App/Pages/Reports/list";
import {EditUser} from "./App/Pages/Customer/edit/edit";
import {WebinarsList} from "./App/Pages/Webinars/General/list";
import {EditWebinar} from "./App/Pages/Webinars/General/edit";
import {AcademiesList} from "./App/Pages/Academy/General/list";
import {EditAcademy} from "./App/Pages/Academy/General/edit";
import SuccessStories from "./App/Pages/SuccessStory/General/successStories";
import {CreateSuccessStory} from "./App/Pages/SuccessStory/General/createSuccessStory";
import {EditSuccessStory} from "./App/Pages/SuccessStory/General/editSuccessStory";
import WebinarHistories from "./App/Pages/WebinarHistory/General/webinarHistories";
import {CreateWebinarHistory} from "./App/Pages/WebinarHistory/General/createWebinarHistory";
import {EditWebinarHistory} from "./App/Pages/WebinarHistory/General/editWebinarHistory";
import {HomeBlocks} from "./App/Pages/HomeBlocks/list";
import DailyInspirations from "./App/Pages/DailyInspiration/General/dailyInspirations";
import {CreateDailyInspiration} from "./App/Pages/DailyInspiration/General/createDailyInspiration";
import {EditDailyInspiration} from "./App/Pages/DailyInspiration/General/editDailyInspiration";

const theme = createTheme({
    ...defaultTheme,
    typography: {
        fontFamily: `'Roboto' , sens-serif`,
    },
    palette: {
        secondary: {
            main: '#F8F2FF'
        }
    },
    header: {
        '& .MuiAppBar-colorSecondary': {
            background: 'linear-gradient(90deg, #8F70C0 0%, #492584 100%)',
        },
        primary: {
            main: "#ff9800",
        },
    },
    sidebar: {
        width: 130,
        closedWidth: 0
    },
    overrides: {
        RaAppBar: {
            toolbar: {
                '& button': {
                    '&:not(:nth-child(1))': {
                        display: 'none'
                    }
                }
            }
        },
        RaMenuItemLink: {
            active: {
                borderLeft: '3px solid #2196f3',
            },
            root: {
                borderLeft: '3px solid #fff',
            },
        },
        MuiToolbar: {
            regular: {
                background: '#fafafa',
            },

        },
        MuiTypography: {
            body2: {
                fontWeight: 400,
            }
        }
    }
});

const MyLoginPage = () => (
    <Login style={{backgroundImage: 'linear-gradient(90deg, #8F70C0 0%, #492584 100%)'}} />
);

const App = () => (

    <Admin theme={theme} dataProvider={dataProvider} authProvider={authProvider} layout={CustomLayout} loginPage={MyLoginPage}>
        <Resource name="home" list={HomeBlocks} />
        <Resource name="products/general" list={Products} create={CreateProduct} edit={EditProduct} />
        <Resource name="files/general" list={Files} create={CreateFile} edit={EditFile}/>
        <Resource name="categories/general" list={Categories} create={CreateCategory} edit={EditCategory} />
        <Resource name="daily-dares/general" list={DailyDareGeneralList} create={DailyDareGeneralCreate} edit={DailyDareGeneralEdit} />
        <Resource name="customers" list={CustomerList} create={CustomerCreate} show={CustomerShow} edit={EditUser} />
        <Resource name="comments/"/>
        <Resource name="export" list={ReportList}/>
        <Resource name="academies/general" list={AcademiesList} edit={EditAcademy}/>
        {/*TODO: If you need create webinar functionality use this -> create={CreateWebinar}*/}
        <Resource name="webinars/general" list={WebinarsList} edit={EditWebinar}/>
        <Resource name="webinar-histories/general" list={WebinarHistories} create={CreateWebinarHistory} edit={EditWebinarHistory}/>
        <Resource name="success-stories/general" list={SuccessStories} create={CreateSuccessStory} edit={EditSuccessStory}/>
        <Resource name="daily-inspirations/general" list={DailyInspirations} create={CreateDailyInspiration} edit={EditDailyInspiration}/>
    </Admin>
);

export default App;
