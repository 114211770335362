export const EBooleanStatuses = {
    True: true,
    False: false
}

export const EBooleanStatusesNumber = {
    True: 1,
    False: 0
}

export const Subscriptions = [
    {id: 'all', name: 'Multilanguage'},
]

export const SubscriptionSources = [
    {id: 1, name: 'Other'},
    {id: 2, name: 'DARE Academy'},
    {id: 3, name: 'Influencer'},
    {id: 4, name: 'Lifetime'},
    {id: 5, name: 'Sponsorship'},
]