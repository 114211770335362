import * as React from "react";
import {TabbedForm, useRedirect} from "react-admin";
import {HomeBlocksLocalization} from "./localization";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
export const HomeBlocks = () => {
    const location = useLocation();
    const redirect = useRedirect();

    useEffect(() => {
        if (!location.pathname.includes('en') && !location.pathname.includes('pt') && !location.pathname.includes('de')) {
            redirect('/home/en')
        }
    }, [location])

    return <div className="home-blocks">
        <h2 className="home-blocks__title">Home Page Blocks Order</h2>
        <div className="home-blocks__wrap">
            <TabbedForm toolbar={null}>
                <TabbedForm.Tab label="EN" path="en">
                    <HomeBlocksLocalization localization={'en'} />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="PT" path="pt">
                    <HomeBlocksLocalization localization={'pt'} />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="DE" path="de">
                    <HomeBlocksLocalization localization={'de'} />
                </TabbedForm.Tab>
            </TabbedForm>
        </div>
    </div>
}