import {
    Edit,
    required,
    TextInput,
    BooleanInput,
    Link,
    SimpleForm,
    useNotify,
    useDataProvider,
    FunctionField,
    useRefresh,
    TabbedShowLayout,
    Tab
} from "react-admin";
import * as React from "react";
import ProductLocalization from "./productLocalization";
import {useEffect, useRef, useState} from "react";
import AsyncSelect from "react-select/async";
import {httpGet, httpGetOneWithParams} from "../../../Api/api";
import {Priorities} from "../../../Component/Layouts/PriorityBlock";
import {useParams} from "react-router-dom";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import setLoadStatus from "../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";

export const EditProduct = () => {
    const {id} = useParams();
    const initialRef = useRef(true);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [categoriesList, setCategoriesList] = useState([]);
    const [files, setFiles] = useState([]);
    const refresh = useRefresh();
    const [formData] = useState({
        name: '',
        categories: [],
        description: '',
        image: '',
        imagePreview: '',
        published: false,
        subscription: false
    });
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        httpGet('products/general/category-list').then(res => {
            if (res.data.length) {
                setCategoriesList(res.data)
            }
        });

    }, []);

    const editCreate = () => {
        const fileOrder = files.find(item => item.order <= 0);
        if (fileOrder) {
            notify(`File Order must be bigger then 0!`, 'warning');
            return;
        }


        let newCategories = formData.categories.map((item) => (item.value ? item.value : item));
        const selectedCategories = !newCategories.length ? [] : formData.categories.map((item) => (item.value ? item.value : item));
        newCategories = newCategories.length ? newCategories : selectedCategories;

        if (!formData.name.length || !newCategories.length) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }
            if (!newCategories.length) {
                notify('Please, choose category.', {type: 'warning'});
                document.querySelector('.async-select').classList.add('error');
            }
            return;
        }

        dispatch(setLoadStatus(true));

        dataProvider.update(`products/general`, {
            id: `${id}`, data: {
                name: formData.name,
                categories: newCategories,
                description: formData.description,
                icon: formData.image,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                published: formData.published ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                files
            }
        }).then(() => {
            notify(`Product update`);
            refresh(true);
            dispatch(setLoadStatus(false));
        }).catch((res) => {
            dispatch(setLoadStatus(false));
            notify(res.message, 'warning');
        });
    };

    const selectCategory = (e) => {
        if (document.querySelector('.async-select').classList.contains('error')) {
            document.querySelector('.async-select').classList.remove('error');
        }

        const selectedCategories = e.map((item) => (item.value));
        formData.categories = selectedCategories;
    }

    const loadOptions = (
        inputValue
    ) => {
        return new Promise((resolve, reject) => {
            return httpGetOneWithParams('products/general/category-list', {label: inputValue}).then((res) => {
                if (res.data.length) {
                    resolve(res.data)
                } else {
                    resolve([])
                }
            }, reject)

        })
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                <Link to="/products/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const Files = ({record}) => {
        if (record.files && record.files.length) {
            setFiles(record.files);
            return (<Priorities data={record.files} title={'Files'} url="/files/general" changeOrder={changeOrder}/>);
        } else {
            return (<></>)
        }
    }

    const changeOrder = (e, fileId) => {
        const currentValue = e.target.value;
        const fileOrder = files.find(item => item.id === fileId);
        fileOrder.order = Number(currentValue);
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    const ImageUploadField = () => {
        const [imageSrc, setImageSrc] = useState(null);

        const uploadImage = async (imageFile) => {
            if (!imageFile) {
                return;
            }

            const mimeType = imageFile.type;
            const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!mimetypes.includes(mimeType)) {
                notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
                return;
            }

            formData.image = imageFile;

            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result);
            }

            reader.readAsDataURL(imageFile);
        }

        return <div className="form-field">
            <span className="fieldTitle">Icon</span>
            <div>
                <div className="imageWrap">
                        <img
                            className="image"
                            src={imageSrc ? imageSrc : formData.image}
                            alt=""
                        />
                    <p style={{display: 'none'}}>Required</p>
                </div>
                <label className="imageLabel" htmlFor="imageInput"></label>
                <input
                    type="file"
                    id="imageInput"
                    className="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadImage(e.currentTarget.files[0])}
                />
                <label htmlFor="imageInput" className="uploadButton">
                    {formData.image ? 'Update Image' : 'Choose an Image'}
                </label>
            </div>
        </div>;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit Product</h2>
            <Edit title="Product">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <div className="tabbedFormWrap">
                                <FunctionField label="" render={(record) => {
                                    formData.name = record.name;
                                    return <>
                                        <TextInput className="formFields name" label="Name" name="name"
                                                   source="formData.name" fullWidth id="name" validate={required()}
                                                   onChange={(e) => {
                                                       nameChange(e)
                                                   }} multiline/>
                                        <p className="required-text">Required</p>
                                    </>;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    if (!record.categories.length) return <div className="async-select-wrap">
                                        <AsyncSelect placeholder="Category" className="async-select" isMulti
                                                     source="categories"
                                                     defaultOptions={categoriesList} loadOptions={loadOptions}
                                                     onChange={(e) => {
                                                         selectCategory(e)
                                                     }}/>
                                        <p className="required-text">Required</p>
                                    </div>;
                                    formData.categories = record.categories;
                                    const selectedCategories = record.categories.reduce((acc, curr) => {
                                        if (curr.value) {
                                            acc.push({value: curr.value, label: curr.label})
                                        }
                                        return acc;
                                    }, []);
                                    return selectedCategories.length &&
                                        <div className="async-select-wrap">
                                            <AsyncSelect placeholder="Category" className="async-select" isMulti
                                                         source="categories" defaultValue={selectedCategories}
                                                         defaultOptions={categoriesList} loadOptions={loadOptions}
                                                         optionText="label" optionValue="value" onChange={(e) => {
                                                selectCategory(e)
                                            }}/>
                                            <p className="required-text">Required</p>
                                        </div>;
                                }} fullWidth/>
                                <FunctionField label="" render={(record) => {
                                    formData.description = record.description;
                                    return <TextInput className="formFields" label="Description" name="description"
                                                      source="formData.description" id="description" multiline fullWidth
                                                      onChange={(e) => {
                                                          formData.description = e.target.value
                                                      }}/>;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    return <Files source="files" record={record}/>
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.image = record.icon_url;
                                    return <ImageUploadField />;
                                }}/>

                                <FunctionField label="" render={(record) => {
                                    formData.published = record.published;
                                    return <BooleanInput name="published" source="published"
                                                         format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                         parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                         onChange={(e) => {
                                                             formData.published = e.target.checked
                                                         }}
                                    />;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.subscription = record.subscription;
                                    return <BooleanInput label="Subscription Required"
                                                         name="subscription" source="subscription"
                                                         format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                         parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                         onChange={(e) => {
                                                             formData.subscription = e.target.checked
                                                         }}/>;
                                }}/>
                            </div>
                            <Save/>
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <ProductLocalization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <ProductLocalization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <ProductLocalization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}