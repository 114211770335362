export const REPORT_TYPE_OF_PROJECT = [
    {id: 1, name: 'Customers'},
    {id: 2, name: 'Subscribers'},
    {id: 3, name: 'Search queries'},
]

export const REPORT_STATUS = [
    {id: 0, name: 'Error'},
    {id: 1, name: 'Pending'},
    {id: 2, name: 'Processing'},
    {id: 3, name: 'Generated'},
]
