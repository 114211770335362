import {
    Edit,
    required,
    TextInput,
    Link,
    FunctionField,
    SimpleForm,
    TabbedShowLayout,
    Tab, useNotify, useDataProvider, useRefresh
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";
import AcademyLocalization from "./academyLocalization";

export const EditAcademy = () => {
    const {id} = useParams();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const [formData] = useState({
        name: '',
    });

    const editCreate = () => {
        if (!formData.name.length) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }

            return;
        }

        dispatch(setLoadStatus(true));
        dataProvider.update(`academies/general`, {id: `${id}`, data: {
                name: formData.name
            }}).then(() => {
            dispatch(setLoadStatus(false));
            refresh(true);
            notify(`Academy general update`);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                    <Link to="/academies/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit Dare Academy</h2>
            <Edit title="Edit Dare Academy">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <FunctionField label="" render={(record) => {
                                formData.name = record.name;
                                return <>
                                    <TextInput className="formFields name" label="Name" source="name" fullWidth id="name" validate={required()} onChange={(e) => {
                                        nameChange(e)
                                    }} multiline/>
                                </>;
                            }}/>
                            <Save />
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <AcademyLocalization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <AcademyLocalization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <AcademyLocalization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}