import * as React from "react";
import {
    Edit,
    TabbedShowLayout,
    Tab
} from 'react-admin';
import {GeneralForm} from "./components/GeneralForm";
import {Localization} from "./components/Localization";
import {useParams} from "react-router-dom";

export const DailyDareGeneralEdit = () => {
    const { id } = useParams();

    return (
        <>
            <h2 className="dailyTitle">Edit Daily Dare</h2>
            <Edit title="Daily Dare">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <GeneralForm id={id}/>
                    </Tab>
                    <Tab label="EN" path="en">
                        <Localization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <Localization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <Localization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    )
};