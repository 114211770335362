import {
    TextInput,
    SimpleForm,
    useCreate,
    useNotify,
    Link,
    useRedirect,
    useDataProvider, required, FunctionField
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {LocalizationActions} from "../../../Component/Layouts/LocalizationActions";
import {useParams} from "react-router-dom";
import setLoadStatus from "../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";

const LOCALIZATION_DATA = {
    id: '',
    member_link: '',
    sales_link: '',
    member_image_url: '',
    sales_image_url: '',
}

const AcademyLocalization = () => {
    const { '*': locale, id } = useParams();
    const initialRef = useRef(true);
    const [localizationData, setLocalizationData] = useState({[locale]: LOCALIZATION_DATA});
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const formData = {
        member_link: '',
        sales_link: '',
        member_image: '',
        sales_image: '',
    };

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        dataProvider.getOne('academies', {id: `${id}/${locale}`}).then((res) => {
            setLocalizationData({[locale]: LOCALIZATION_DATA});
            if (res.data.id !== 0) {
                setLocalizationData({[locale]: res.data});
            }
        });
    }, [dataProvider, id, locale])

    const academyLocalizationCreate = () => {
        if (!formData.member_link.length || !formData.sales_link.length || !formData.member_image || !formData.sales_image) {
            if (!formData.member_link.length) {
                document.querySelector('.member_link').classList.add('error');
            }

            if (!formData.sales_link.length) {
                document.querySelector('.sales_link').classList.add('error');
            }

            if (!formData.member_image.length) {
                document.querySelector('.member_image-required').classList.add('error');
            }

            if (!formData.sales_image.length) {
                document.querySelector('.sales_image-required').classList.add('error');
            }

            return;
        }
        dispatch(setLoadStatus(true));
        if (!localizationData[locale].id) {
            create(`academies/${id}/${locale}`, {data: {
                    member_link: formData.member_link,
                    sales_link: formData.sales_link,
                    member_image: formData.member_image,
                    sales_image: formData.sales_image
                }}, {
                onSuccess: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(`Dare academy localization ${locale} created`);
                    redirect(`/academies/general/${id}`);
                },
                onError: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(res.message, 'warning');
                }
            });
        }

        if (localizationData[locale].id) {
            dataProvider.update(`academies`, {id: `${id}/${locale}`, data: {
                    member_link: formData.member_link,
                    sales_link: formData.sales_link,
                    member_image: formData.member_image,
                    sales_image: formData.sales_image
                }}).then(() => {
                dispatch(setLoadStatus(false));
                notify(`Dare academy localization ${locale} update`);
                redirect(`/academies/general/${id}`);
            }).catch((res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            });
        }
    }

    const Save = ({...props}) => {
        return (
            <div className="dailyActionToolbar">
                {props.localizationId &&
                    <LocalizationActions id={id} status={props.localizationStatus} url={'academies'} redirectUrl={'/academies/general'} prefix={locale}/>
                }
                <Link to="/academies/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={academyLocalizationCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const MemberImageUploadField = () => {
        const [memberImageSrc, setMemberImageSrc] = useState(null);

        const uploadImage = async (imageFile) => {
            if (!imageFile) {
                return;
            }

            const mimeType = imageFile.type;
            const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!mimetypes.includes(mimeType)) {
                notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
                return;
            }

            if (document.querySelector('.member_image-required').classList.contains('error')) {
                document.querySelector('.member_image-required').classList.remove('error');
            }

            formData.member_image = imageFile;

            const reader = new FileReader();

            reader.onload = () => {
                setMemberImageSrc(reader.result);
            }

            reader.readAsDataURL(imageFile);
        }

        return <div className="form-field member_image-required">
            <span className="fieldTitle">Member Image</span>
            <div>
                <div className="imageWrap">
                        <img
                            className="member_image"
                            src={memberImageSrc ? memberImageSrc : formData.member_image}
                            alt=""
                        />
                    <p className="required-text">Required</p>
                </div>
                <label className="imageLabel" htmlFor="memberImageInput"></label>
                <input
                    type="file"
                    id="memberImageInput"
                    className="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadImage(e.currentTarget.files[0])}
                />
                <label htmlFor="memberImageInput" className="uploadButton">
                    {formData.member_image ? 'Update Image' : 'Choose an Image'}
                </label>
            </div>
        </div>;
    }

    const SalesImageUploadField = () => {
        const [salesImageSrc, setSalesImageSrc] = useState(null);

        const uploadImage = async (imageFile) => {
            if (!imageFile) {
                return;
            }

            const mimeType = imageFile.type;
            const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!mimetypes.includes(mimeType)) {
                notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
                return;
            }

            if (document.querySelector('.sales_image-required').classList.contains('error')) {
                document.querySelector('.sales_image-required').classList.remove('error');
            }

            formData.sales_image = imageFile;

            const reader = new FileReader();

            reader.onload = () => {
                setSalesImageSrc(reader.result);
            }

            reader.readAsDataURL(imageFile);
        }

        return <div className="form-field sales_image-required">
            <span className="fieldTitle">Non-Member Image</span>
            <div>
                <div className="imageWrap">
                    <img
                        className="sales_image"
                        src={salesImageSrc ? salesImageSrc : formData.sales_image}
                        alt=""
                    />
                    <p className="required-text">Required</p>
                </div>
                <label className="imageLabel" htmlFor="salesImageInput"></label>
                <input
                    type="file"
                    id="salesImageInput"
                    className="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadImage(e.currentTarget.files[0])}
                />
                <label htmlFor="salesImageInput" className="uploadButton">
                    {formData.sales_image ? 'Update Image' : 'Choose an Image'}
                </label>
            </div>
        </div>;
    }

    const memberLinkChange = (e) => {
        if (document.querySelector('.member_link').classList.contains('error')) {
            document.querySelector('.member_link').classList.remove('error');
        }

        formData.member_link = e.target.value;
    }

    const salesLinkChange = (e) => {
        if (document.querySelector('.sales_link').classList.contains('error')) {
            document.querySelector('.sales_link').classList.remove('error');
        }

        formData.sales_link = e.target.value;
    }

    return (
        <SimpleForm record={localizationData[locale]} toolbar={<></>}>
                <FunctionField label="" render={(record) => {
                    formData.member_link = record.member_link;
                    return <>
                        <TextInput className="formFields member_link" label="Member Login Link" name="member_link"
                               source="formData.member_link" fullWidth id="member_link" validate={required()}
                               onChange={(e) => {
                                   memberLinkChange(e)
                               }} multiline/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <p className="uploadImageLabel"></p>
                <FunctionField label="" render={(record) => {
                    formData.member_image = record.member_image_url;
                    return <MemberImageUploadField />;
                }}/>
                <FunctionField label="" render={(record) => {
                    formData.sales_link = record.sales_link;
                    return <>
                        <TextInput className="formFields sales_link" label="Sales Page Link" name="sales_link"
                                   source="formData.sales_link" fullWidth id="sales_link" validate={required()}
                                   onChange={(e) => {
                                       salesLinkChange(e)
                                   }} multiline/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <p className="uploadImageLabel"></p>
                <FunctionField label="" render={(record) => {
                    formData.sales_image = record.sales_image_url;
                    return <SalesImageUploadField />;
                }}/>
                <Save localizationId={localizationData[locale].id} localizationStatus={localizationData[locale].status} />
        </SimpleForm>
    );
}

export default AcademyLocalization;