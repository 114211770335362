import React from "react";
import {convertedDateWithTimeGMT} from "../../../Functions/ConvertedDate";
import {LabelValueFromConst} from "../../../Component/Handlers/ValueFromConst";
import {SubscriptionSources} from "../../../Const/Enums";

const UserDetails = ({record, classes}) => {
    const data = [
        {
            label: 'ID',
            value: record.id
        },
        {
            label: 'First Name',
            value: record.first_name
        },
        {
            label: 'Last Name',
            value: record.last_name
        },
        {
            label: 'Email',
            value: record.email
        },
        {
            label: 'Auth Type',
            value: record.auth_type
        },
        {
            label: 'Subscription',
            value: record.subscription
        },
        {
            label: 'Subscription Type',
            value: record.subscription_type === 1 ? 'Paid' : (record.subscription_type === 2 ? 'Manual' : record.subscription_type)
        },
        {
            label: 'Subscription Source',
            value: LabelValueFromConst(SubscriptionSources, record.subscription_source, '-')
        },
        {
            label: 'Dare Academy Subscription',
            value: record.academy_subscription === 1 ? 'Yes' : 'No'
        },
        {
            label: 'Next Renewal Date (GMT)',
            value: record.renewal ? convertedDateWithTimeGMT(record.renewal) : '-'
        },
        {
            label: 'Last Login (GMT)',
            value: record.last_login ? convertedDateWithTimeGMT(record.last_login) : '-'
        },
        {
            label: 'Account Created (GMT)',
            value: record.created ? convertedDateWithTimeGMT(record.created) : '-'
        },
        {
            label: 'Appsflyer ID',
            value: record.appsflyer_id
        },
        {
            label: 'Clevertap ID',
            value: record.clevertap_id
        }
    ]

    return (
        <div className={classes.userDetails}>
            <h3 className={classes.title}>User Details</h3>
            {data.map((item, i) => {
                return (
                    <div className={classes.userDetailsRow} key={i}>
                        <h4 className={classes.userDetailsTitle}>{item.label}</h4>
                        <p className={classes.userDetailsDescription}>{item.value}</p>
                    </div>
                );
            })}
        </div>
    );
}

export default UserDetails;