import * as React from "react";
import {EditButton,  ShowButton} from 'react-admin';
import {DeleteWithConfirmationModalButton} from "../../Redesign/DeleteWithConfirmationModalButton";

const CrudMod = (props) => {
    const {del, show, edit} = props;
    const pencilIcon = <svg className="MuiSvgIcon-root RaButton-smallIcon-9" style={{fill: 'currentcolor', width: '1em', height: '1em'}} focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path></svg>;

    return (<div className="crud-mode">
                {show ? (<ShowButton {...props} label='' icon={pencilIcon} />) : ''}
                {edit ? (<EditButton {...props} label=''/>) : ''}
                {del === "true" ? (<DeleteWithConfirmationModalButton undoable={false} {...props} label='' />) : ''}
            </div>);
};

export default CrudMod;