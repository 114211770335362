import React from 'react';
import {
    Datagrid,
    TextField,
    TopToolbar,
    Filter,
    TextInput,
    SelectField,
    FunctionField,
    SelectInput
} from 'react-admin';
import ListMod from "../../Component/Redesign/ListMod";
import CrudMod from "../../Component/Layouts/Button/CrudMod";
import {makeStyles} from "@material-ui/core/styles";
import {convertedDateWithTimeGMT} from "../../Functions/ConvertedDate";
import CreateGradientButtonMod from "../../Component/Layouts/Button/CreateGradientButtonMod";
import {Subscription} from "./components/Subscription";
import {SubscriptionSources} from "../../Const/Enums";

const useStyles = makeStyles({
    customTable: {
        '& th span': {
            fontWeight: '500'
        },

        '& td:nth-child(2), & td:nth-child(3)': {
            '& span': {
                display: 'block',
                maxWidth: '100%',
                width: '100%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
            }
        },

        '& th, & td': {
            padding: '6px 8px',
            textAlign: 'center',

            '&:nth-child(1)': {
                textAlign: 'left'
            },

            '&:nth-child(2)': {
                maxWidth: '223px',
                minWidth: '100px',
                textAlign: 'left',

                '& span': {
                    wordBreak: 'break-all'
                }
            },

            '&:nth-child(3)': {
                maxWidth: '223px',
                textAlign: 'left'
            },

            '&:nth-child(4)': {
                minWidth: '101px',
            },

            '&:nth-child(5)': {
                minWidth: '101px',
            },

            '&:nth-child(6)': {
                minWidth: '120px',
            },

            '&:nth-child(7)': {
                minWidth: '120px',
            },

            '&:nth-child(8)': {
                minWidth: '84px',
            },

            '&:nth-child(9)': {
                minWidth: '126px',
            },

            '&:nth-child(10)': {
                minWidth: '156px',
            },

            '&:nth-child(11)': {
                width: '73px'
            },

            '& .MuiButton-root': {
                minWidth: 'unset'
            }
        }
    }
});

const ListActions = () => {
    return (<TopToolbar>
        <CreateGradientButtonMod label={'Add New Customer'} />
    </TopToolbar>);
};

const Filters = () => (
    <Filter>
        <TextInput variant="standard" label="Search" source="search" alwaysOn />
        <SelectInput variant="standard" label="Search by source" source="source" name="source" choices={SubscriptionSources} alwaysOn />
    </Filter>
);

export const CustomerList = () => {
    const classes = useStyles();

    return (
        <div className="customTableWrap">
            <ListMod actions={<ListActions />}
                     filters={<Filters />}
            >
                <Datagrid bulkActionButtons={false} className={classes.customTable}>
                    <TextField label='ID' source="id" sortBy="id"/>
                    <TextField label='Name' source="name" sortBy="name"/>
                    <FunctionField label='Email' source="email" render={(record) => {
                        return (
                            <span title={record.email ? record.email : null}>{record.email}</span>
                        );
                    }}/>
                    <TextField label='Auth Type' source="auth_type" sortBy="auth_type"/>
                    <TextField label='Subscription' source="subscription" sortBy="subscription"/>
                    <SelectField source="subscription_type" label="Subscription Type" choices={[
                        {id: null, name: '-'},
                        {id: 1, name: 'Paid'},
                        {id: 2, name: 'Manual'}
                    ]}/>
                    <SelectField
                        source="subscription_source"
                        label="Subscription Source"
                        choices={[{id: null, name: '-'}, ...SubscriptionSources]}
                    />
                    <FunctionField label="Next Renewal Date (GMT)" source="renewal" render={(record) => {
                        return (record.renewal ? convertedDateWithTimeGMT(record.renewal) : '-');
                    }}/>
                    <SelectField source="platform" label="Platform" choices={[
                        {id: null, name: '-'},
                        {id: 1, name: 'Android'},
                        {id: 2, name: 'iOS'}
                    ]}/>
                    <FunctionField label="Account Created (GMT)" source="created" render={(record) => {
                        return (convertedDateWithTimeGMT(record.created));
                    }}/>
                    <FunctionField label="" source="manual_subscription" render={(record) => {
                        return record.can_grant_access ? <Subscription {...record}/> : '';
                    }}/>
                    <FunctionField label="" source="actions" render={(record) => {
                        return <CrudMod show="true" del="true" id={record.id} record={record}/>;
                    }}/>
                </Datagrid>
            </ListMod>
        </div>
    );
};
