import React, {useCallback} from 'react';
import {useTranslate} from "ra-core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import {alpha} from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles(theme => ({
        confirmPrimary: {
            color: theme.palette.primary.main,
        },
        confirmWarning: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
        customDialog: {
            '& .MuiDialog-paper': {
                maxWidth: '528px',
                width: '100%',
                background: '#FFFFFF',
                boxShadow: '0px 16px 32px rgba(43, 28, 54, 0.12)',
                borderRadius: '4px'
            },

            '& .MuiDialogTitle-root ': {
                padding: '26px 24px 22px',
                borderBottom: '1px solid #F5F5F5',

                '& h2': {
                    fontFamily: `'Roboto', sans-serif`,
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                    color: '#323238'
                },
            },

            '& .MuiDialogContent-root': {
                padding: '12px 24px',
                borderBottom: '1px solid #F5F5F5',

                '& p': {
                    fontFamily: `'Roboto', sans-serif`,
                    fontStyle: 'normal',
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.25px',
                    color: '#323238',
                    margin: '0'
                }
            },

            '& .MuiDialogActions-root': {
                padding: '14px 12px',
            }
        },
        iconPaddingStyle: {
            paddingRight: '0.5em',
        },
        deleteButton: {
            fontFamily: `'Roboto', sans-serif`,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: '#FFFFFF',

            whiteSpace: 'nowrap',
            textTransform: 'unset',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            background: '#ED6868',
            borderRadius: '4px',

            border: 'none',
            outline: 'none',

            cursor: 'pointer'
        },
        gradientButton: {
            fontFamily: `'Roboto', sans-serif`,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: '#FFFFFF',

            whiteSpace: 'nowrap',
            textTransform: 'unset',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            background: 'linear-gradient(90deg, #8F70C0 0%, #492584 100%)',
            borderRadius: '4px',

            border: 'none',
            outline: 'none',

            cursor: 'pointer'
        },
        purpleBorderButton: {
            fontFamily: `'Roboto', sans-serif`,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: '#8F70C0',

            whiteSpace: 'nowrap',
            textTransform: 'unset',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '7px 24px',
            background: 'unset',
            borderRadius: '4px',

            marginRight: '8px',

            border: '1px solid #8F70C0',
            outline: 'none',

            cursor: 'pointer'
        }
    }),
    { name: 'RaConfirm' }
);

const ConfirmModal = (props) => {
    const {
        isOpen,
        loading,
        title,
        content,
        cancel,
        onClose,
        onConfirm,
        type,
        translateOptions = {},
    } = props;
    const classes = useStyles(props);
    const translate = useTranslate();

    const handleConfirm = useCallback(
        e => {
            e.stopPropagation();
            onConfirm(e);
        },
        [onConfirm]
    );

    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
            className={classes.customDialog}
        >
            <DialogTitle id="alert-dialog-title">
                {translate(title, { _: title, ...translateOptions })}
            </DialogTitle>
            <DialogContent>
                {typeof content === 'string' ? (
                    <DialogContentText>
                        {translate(content, {
                            _: content,
                            ...translateOptions,
                        })}
                    </DialogContentText>
                ) : (
                    content
                )}
            </DialogContent>
            <DialogActions>
                <button
                    type={'button'}
                    className={classes.purpleBorderButton}
                    disabled={loading}
                    onClick={onClose}>
                    {translate(cancel, { _: cancel })}
                </button>
                {type === 'delete' && <button type={'button'}
                                              disabled={loading}
                                              onClick={handleConfirm}
                                              className={classes.deleteButton}>
                    {/*{translate(confirm, { _: confirm })}*/}
                    Delete
                </button>}
                {type === 'disable' && <button type={'button'}
                                              disabled={loading}
                                              onClick={handleConfirm}
                                              className={classes.deleteButton}>
                    {/*{translate(confirm, { _: confirm })}*/}
                    Disable
                </button>}
                {type === 'enable' && <button type={'button'}
                                              disabled={loading}
                                              onClick={handleConfirm}
                                              className={classes.gradientButton}>
                    Enable
                    {/*{translate(confirm, { _: confirm })}*/}
                </button>}
            </DialogActions>
        </Dialog>
    );
};

ConfirmModal.propTypes = {
    cancel: PropTypes.string,
    classes: PropTypes.object,
    confirm: PropTypes.string,
    content: PropTypes.node.isRequired,
    isOpen: PropTypes.bool,
    loading: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

ConfirmModal.defaultProps = {
    cancel: 'ra.action.cancel',
    classes: {},
    confirm: 'ra.action.confirm',
    isOpen: false,
};

export default ConfirmModal;