import {
    Create,
    required,
    minValue,
    TextInput,
    BooleanInput,
    NumberInput,
    Link, useCreate, useNotify, useRedirect, TabbedForm, FormTab
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";

export const CreateCategory = () => {
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dispatch = useDispatch();

    const [formData] = useState({
        name: '',
        order: '',
        description: '',
        subscription: false,
        main: false,
        onboarding: false
    });


    const categoryCreate = () => {
        dispatch(setLoadStatus(true));
        if (!formData.name.length || formData.order <= 0) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }
            if (formData.order <= 0) {
                document.querySelector('.order').classList.add('error');
            }

            dispatch(setLoadStatus(false));
            return;
        }

        create('categories/general', {data: {
                name: formData.name,
                order: formData.order,
                description: formData.description,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                main: formData.main ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                onboarding: formData.onboarding ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False
            }}, {
            onSuccess: (res) => {
                dispatch(setLoadStatus(false));
                redirect(`/categories/general/${res.id}/en`);
                notify('Category created');
            },
            onError: (res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            }
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                    <Link to="/categories/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={categoryCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    const orderChange = (e) => {
        const val = +e.target.value;
        if (val <= 0) {
            document.querySelector('.order').classList.add('error');
            return;
        }
        if (document.querySelector('.order').classList.contains('error')) {
            document.querySelector('.order').classList.remove('error');
        }
        formData.order = val;
    }

    return (
        <>
            <h2 className="dailyTitle">New Category</h2>
            <Create title="Create Category">
                <TabbedForm className="tabbedForm" record={formData} toolbar={<></>}>
                <FormTab label="General">
                    <div className="tabbedFormWrap">
                        <TextInput className="formFields name" label="Name" name="name" source="name" fullWidth id="name" validate={required()} onChange={(e) => {nameChange(e)}} multiline />
                        <p className="required-text">Required</p>
                        <NumberInput label="Order" name="order" source="order" min={1} className="numberInput order" fullWidth id="order" validate={[required(), minValue(1)]} onChange={(e) => {orderChange(e)}} />
                        <p className="required-text">Must be at least 1</p>
                        <TextInput className="formFields" label="Description" name="description" source="description" id="description" multiline fullWidth onChange={(e) => {formData.description = e.target.value}} />
                        <BooleanInput label="Subscription Required" source="subscription" className="booleanInput" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.subscription = e.target.checked}}/>
                        <p className="booleanInputDescription">Categories Place</p>
                        <BooleanInput label="Main" source="main" className="booleanInput" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.main = e.target.checked}}/>
                        <BooleanInput label="Onboarding" source="onboarding" className="booleanInput" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.onboarding = e.target.checked}}/>
                    </div>
                    <Save />
                </FormTab>
                <FormTab label="EN" path="en" disabled={true}>
                    <>
                        <p>No Data</p>
                    </>
                </FormTab>
                <FormTab label="PT" path="pt" disabled={true}>
                    <>
                        <p>No Data</p>
                    </>
                </FormTab>
                <FormTab label="DE" path="de" disabled={true}>
                    <>
                        <p>No Data</p>
                    </>
                </FormTab>
            </TabbedForm>
            </Create>
        </>
    );
}