export const handleDownload = async (fileName, url) => {
    try {
        if (typeof document === 'undefined') return;
        const response = await fetch(url);
        const data = await response.blob();

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(data);
        link.download = fileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};