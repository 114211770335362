import {styled, useTheme} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import clsx from "clsx";
import * as React from "react";

export const Loader = (props) => {
    const { className, sx, ...rest } = props;

    const theme = useTheme();
    return (
        <Root className={className} sx={sx}>
            <div className="loader">
                <CircularProgress
                    className={clsx(
                        'app-loader',
                        LoadingIndicatorClasses.loader
                    )}
                    color="inherit"
                    size={theme.spacing(8)}
                    thickness={6}
                    {...rest}
                />
            </div>
        </Root>
    );
};

const PREFIX = 'RaLoadingIndicator';

export const LoadingIndicatorClasses = {
    loader: `${PREFIX}-loader`,
    loadedIcon: `${PREFIX}-loadedIcon`,
};

const Root = styled('div', {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    [`& .${LoadingIndicatorClasses.loader}`]: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
    },

    [`& .${LoadingIndicatorClasses.loadedIcon}`]: {},
}));