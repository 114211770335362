import {REPORT_TYPE_OF_PROJECT} from "../../../Const/SelectionChoices";
import {DateInput, RadioButtonGroupInput, required, SimpleForm, useCreate, useNotify, useRefresh} from "react-admin";
import React from "react";
import {useFormContext} from "react-hook-form";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    form: {
        '&.MuiCardContent-root': {
            padding: 0
        },
        '& .form-row': {
            display: 'flex',
            alignItems: 'center'
        },
        '& .MuiFormControl-root': {
            marginRight: '16px'
        }
    }
})

const validateDates = (values) => {
    const errors = {}
    if (values.from && values.to) {
        const dateFrom = new Date(values.from)
        const dateTo = new Date(values.to)
        if (dateTo < dateFrom) {
            errors.to = 'Date to must be more than from';
        }
    }
    return errors
};

const SubmitAction = ({isLoading, handleSubmit}) => {
    const form = useFormContext()

    return (
        <button type="submit" className="button gradient-button" onClick={() => handleSubmit(form)}
                disabled={isLoading}>Generate</button>
    )
}

export const GenerateReportForm = () => {

    const defaultValues = () => ({
        type: REPORT_TYPE_OF_PROJECT[0].id
    })
    const styles = useStyles()
    const [create, {isLoading}] = useCreate()
    const notify = useNotify()
    const refresh = useRefresh()


    const handleSubmit = (form) => {
        const {reset, getValues, formState} = form
        if (!formState.isValid) {
            return
        }
        const {type, from, to} = getValues()

        create(
            `export/generate/${type}`,
            {data: {from, to}},
            {
                onSuccess: () => {
                    notify('Export reports has started')
                    refresh()
                    reset()
                },
                onError: (err) => {
                    notify(err.message, {type: 'error'})
                }
            })
    }


    return (
        <SimpleForm sanitizeEmptyValues validate={validateDates} defaultValues={defaultValues}
                    toolbar={false}
                    mode="onChange"
                    className={styles.form}>
            <RadioButtonGroupInput source="type" label={false} choices={REPORT_TYPE_OF_PROJECT} validate={required()}/>
            <div className="form-row">
                <DateInput variant="filled" source="from" name="from"/>
                <DateInput variant="filled" source="to" name="to"/>
                <SubmitAction isLoading={isLoading} handleSubmit={handleSubmit}/>
            </div>
        </SimpleForm>
    )
}
