import * as React from "react";
import {
    Create,
    FormTab,
    TabbedForm,
    TextInput,
    required
} from 'react-admin';
import {DailyDareGeneralActions} from "./components/dailyDareGeneralActions";
import {Calendar} from "react-multi-date-picker"
import {useState} from "react";

export const DailyDareGeneralCreate = () => {
    const [date, setDate] = useState('');
    const [calendarProps, setCalendarProps] = useState({
        value: new Date(),
        format: "YYYY-MM-DD",
        onChange: (date) => setDate(date.format()),
    });

    return (
        <>
            <h2 className="dailyTitle">New Daily Dare</h2>
            <Create>
                <TabbedForm className="tabbedForm" toolbar={<></>}>
                    <FormTab label="General">
                        <>
                            <div className="formFields">
                                <TextInput label="Name" name="name" source="name" fullWidth id="name" validate={required()} multiline/>
                                <TextInput label="Description" name="description" source="description" id="description" multiline fullWidth />
                                <span className="showDatelabel">Show Date</span>
                                <Calendar
                                    {...calendarProps}
                                    onPropsChange={setCalendarProps}
                                    minDate="2000/01/01"
                                    maxDate="3000/12/31"
                                />
                                <input type="hidden" id="show_date" value={date}/>
                            </div>
                        </>
                        <DailyDareGeneralActions />
                    </FormTab>
                    <FormTab label="EN" disabled={true}>
                            <>
                                <p>No Data</p>
                            </>
                    </FormTab>
                    <FormTab label="PT" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="DE" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                </TabbedForm>
            </Create>
        </>
    )
};