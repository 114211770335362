import {
    Create,
    required,
    TextInput,
    BooleanInput,
    Link,
    useCreate,
    useNotify,
    useRedirect, TabbedForm, FormTab
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import AsyncSelect from "react-select/async";
import {httpGet, httpGetOneWithParams} from "../../../Api/api";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";

export const CreateFile = () => {
    const initialRef = useRef(true);
    const [create] = useCreate();
    const [productsList, setProductsList] = useState([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const dispatch = useDispatch();

    const [formData] = useState({
        name: '',
        description: '',
        published: false,
        products: []
    });

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        httpGet('files/general/product-list').then(res => {
            if (res.data.length) {
                setProductsList(res.data)
            }
        });
    }, [])

    const fileCreate = () => {
        if (document.querySelector('.async-select').classList.contains('error')) {
            document.querySelector('.async-select').classList.remove('error');
        }
        if (!formData.name.length || !formData.products.length) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }

            if (!formData.products.length) {
                document.querySelector('.async-select').classList.add('error');
                notify('Please, choose product.', {type: 'warning'});
            }

            return;
        }
        dispatch(setLoadStatus(true));
        create('files/general', {data: {
                name: formData.name,
                description: formData.description,
                products: formData.products,
                published: formData.published ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False
            }}, {
            onSuccess: (res) => {
                dispatch(setLoadStatus(false));
                redirect(`/files/general/${res.id}/en`);
                notify('File created');
            },
            onError: (res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            }
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                <Link to="/files/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={fileCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const loadOptions = (
        inputValue
    ) => {
        return new Promise((resolve, reject) => {
            return httpGetOneWithParams('files/general/product-list', {label: inputValue}).then((res) => {
                if (res.data.length) {
                    resolve(res.data)
                } else {
                    resolve([])
                }
            }, reject)

        })
    };

    const selectProducts = (e) => {
        if (document.querySelector('.async-select').classList.contains('error')) {
            document.querySelector('.async-select').classList.remove('error');
        }

        formData.products = e.map((item) => {
            return item.value;
        });
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">New File</h2>
            <Create title="File">
                <TabbedForm className="tabbedForm" record={formData} toolbar={<></>}>
                    <FormTab label="General">
                        <div className="tabbedFormWrap">
                            <TextInput className="formFields name" label="Name" name="name" source="formData.name" fullWidth id="name" validate={required()} onChange={(e) => {nameChange(e)}} multiline />
                            <p className="required-text">Required</p>
                            <TextInput className="formFields" label="Description" name="description" source="formData.description" id="description" multiline fullWidth onChange={(e) => {formData.description = e.target.value}} />
                            <div className="async-select-wrap">
                                <AsyncSelect className="async-select" isMulti placeholder="Product" defaultOptions={productsList} loadOptions={loadOptions} onChange={(e) => {selectProducts(e)}}/>
                                <p className="required-text">Required</p>
                            </div>
                            <BooleanInput label="Visible" name="published" source="formData.published" className="booleanInput" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.published = e.target.checked}}/>
                        </div>
                        <Save />
                    </FormTab>
                    <FormTab label="EN" path="en" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="PT" path="pt" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="DE" path="de" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                </TabbedForm>
            </Create>
        </>
    );
}