import {
    Create,
    required,
    TextInput,
    BooleanInput,
    Link,
    useCreate,
    useNotify,
    useRedirect, TabbedForm, FormTab, FunctionField
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";
import {Calendar} from "react-multi-date-picker";
import {convertedDate, convertedDateWithTime} from "../../../Functions/ConvertedDate";

export const CreateDailyInspiration = () => {
    const initialRef = useRef(true);
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dispatch = useDispatch();

    const onDateChange = (e) => {
        if (e && e.value.unix) {
            const date = new Date(e.value.unix * 1000);
            formData.show_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        }
    }

    const [formData] = useState({
        name: '',
        show_date: '',
        published: true,
        subscription: true
    });

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;
    }, [])

    const fileCreate = () => {
        if (!formData.name.length) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }

            return;
        }

        dispatch(setLoadStatus(true));

        create('daily-inspirations/general', {data: {
                name: formData.name,
                show_date: formData.show_date || document.getElementById('show_date').value,
                published: formData.published ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False
            }}, {
            onSuccess: (res) => {
                dispatch(setLoadStatus(false));
                redirect(`/daily-inspirations/general/${res.id}/en`);
                notify('Daily inspiration created');
            },
            onError: (res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            }
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                <Link to="/daily-inspirations/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={fileCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">New Daily Inspiration</h2>
            <Create title="Webinar">
                <TabbedForm className="tabbedForm" record={formData} toolbar={<></>}>
                    <FormTab label="General">
                        <div className="tabbedFormWrap">
                            <TextInput className="formFields name" label="Name" name="name" source="formData.name" fullWidth id="name" validate={required()} onChange={(e) => {nameChange(e)}} multiline />
                            <p className="required-text">Required</p>
                            <span className="showDateLabel">Show Date</span>
                            <FunctionField source="show_date" render={(record) => {
                                return (
                                    <>
                                        <Calendar
                                            value={record.show_date ? convertedDateWithTime(record.show_date) : null}
                                            onPropsChange={(e) => {
                                                onDateChange(e)
                                            }}
                                            minDate="2000/01/01"
                                            maxDate="3000/12/31"
                                        />
                                        <input type="hidden" id="show_date"
                                               value={record.show_date && !formData.show_date ? convertedDate(record.show_date) : formData.show_date}/>
                                    </>
                                );
                            }}/>
                            <BooleanInput label="Visible" name="published" source="formData.published" className="booleanInput" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.published = e.target.checked}}/>
                            <BooleanInput label="Subscription Required" source="subscription" className="booleanInput" defaultValue={EBooleanStatuses.False} onChange={(e) => {formData.subscription = e.target.checked}}/>
                        </div>
                        <Save />
                    </FormTab>
                    <FormTab label="EN" path="en" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="PT" path="pt" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                    <FormTab label="DE" path="de" disabled={true}>
                        <>
                            <p>No Data</p>
                        </>
                    </FormTab>
                </TabbedForm>
            </Create>
        </>
    );
}