import ReactModal from "react-modal";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PurpleBorderButton from "../../../Component/Layouts/Button/PurpleBorderButton";
import {minLength, PasswordInput, required, SaveButton, SimpleForm, useNotify} from "react-admin";
import {httpCustomEdit} from "../../../Api/api";

const useStyles = makeStyles({
    modal: {
        zIndex: '999',
        maxWidth: '640px',
        width: '100%',
        background: '#fff',
        padding: '32px'
    },
    modalTitle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0 0 34px',

        fontFamily: `'Roboto', sans-serif`,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: '#323238'
    },
    closeModalBtn: {
        display: 'flex',
        alignItems: 'center',
        color: '#778CA2',
        border: 'none',
        outline: 'none',
        background: 'unset',
        cursor: 'pointer'
    },
    overlay: {
        position: 'fixed',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: '99',
        background: 'rgba(18, 18, 18, 0.4)'
    },
    modalOpen: {
        overflow: 'hidden'
    }
});


export const ChangePasswordForm = ({customerId, onClose}) => {
    const classes = useStyles();
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [passwordsError, setPasswordsError] = useState('')
    const notify = useNotify();

    useEffect(() => {
        if (passwordConfirm !== password) {
            setPasswordsError('Passwords don\'t match')
        } else {
            setPasswordsError('')
        }
    }, [password, passwordConfirm])

    const onSubmit = () => {
        if (passwordConfirm !== password) {
            setPasswordsError('Passwords don\'t match')
            return
        }
        setPasswordsError('')
        const url = `customers/${customerId}/change-password`
        httpCustomEdit(url, {password}).then((res) => {
            notify(res.json.message);
            onClose()
        }).catch((res) => {
            notify(res.message, 'warning');
        });
    }

    const Save = () => {
        return (
            <div className="customerActionToolbar change-password-btns">
                <PurpleBorderButton type="button" label={'Cancel'} onClick={onClose}/>
                <SaveButton alwaysEnable label="Save" className="button bar-gradient-button" icon={<></>} onClick={onSubmit}/>
            </div>
        );
    }

    return (
        <>
            <ReactModal
                isOpen
                shouldCloseOnOverlayClick={true}
                bodyOpenClassName={classes.modalOpen}
                ariaHideApp={false}
                overlayClassName={classes.overlay}
                className={classes.modal}
                contentLabel="Example Modal"
                onRequestClose={onClose}
            >
                <h3 className={classes.modalTitle}>
                    Change Password
                    <button className={classes.closeModalBtn} onClick={onClose}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M8.03595 7L13.7854 12.7495C14.0715 13.0356 14.0715 13.4994 13.7854 13.7854C13.4994 14.0715 13.0356 14.0715 12.7495 13.7854L7 8.03595L1.2505 13.7854C0.964429 14.0715 0.50062 14.0715 0.214552 13.7854C-0.0715172 13.4994 -0.0715172 13.0356 0.214552 12.7495L5.96405 7L0.214552 1.2505C-0.0715172 0.964429 -0.0715172 0.50062 0.214552 0.214552C0.50062 -0.0715172 0.964429 -0.0715172 1.2505 0.214552L7 5.96405L12.7495 0.214552C13.0356 -0.0715172 13.4994 -0.0715172 13.7854 0.214552C14.0715 0.50062 14.0715 0.964429 13.7854 1.2505L8.03595 7Z" fill="#778CA2"/>
                        </svg>
                    </button>
                </h3>
                <SimpleForm toolbar={<Save/>}>
                    <div className={`change-password-form__inputs-wrap ${passwordsError ? 'confirm-password-error' : ''}`}>
                        <PasswordInput label="Password" name="password" source="password"  fullWidth id="password"
                                       className="formFields password" value={password} onChange={(e) => {
                            setPassword(e.target.value)
                        }} validate={[required(), minLength(8)]} initiallyVisible={false}/>
                        <PasswordInput label="Confirm New Password" name="passwordConfirm" source="passwordConfirm" fullWidth id="passwordConfirm"
                                       className="formFields password" value={passwordConfirm} onChange={(e) => {
                            setPasswordConfirm(e.target.value)
                        }} validate={[required(), minLength(8)]} initiallyVisible={false}/>
                        {passwordsError && <p className="passwords-error">{passwordsError}</p>}
                    </div>
                </SimpleForm>
            </ReactModal>
        </>
    );
}