import React from "react";
import ListMod from "../../../Component/Redesign/ListMod";
import {Datagrid, FunctionField, TextField} from "react-admin";
import CrudMod from "../../../Component/Layouts/Button/CrudMod";

export const AcademiesList = () => {
    const LocalsField = ({record}) => {
        if (!record.locales || !record.locales.length) {
            return '';
        }

        const lastElement = record.locales.length - 1;
        return record.locales.map((item, i) => (
            <span key={i}>{item.locale}{i === lastElement ? '' : ', '}</span>
        ))
    };

    return <div className="customTableWrap pt-32">
        <ListMod title={'Dare Academies'} actions={false}>
            <Datagrid bulkActionButtons={false}>
                <TextField label='Name' source="name" sortBy="name"/>
                <FunctionField label='Localization' source="locales" sortBy="locales" render={(record) => {
                    return <LocalsField record={record}/>
                }}/>
                <FunctionField label="" source="actions" render={(record) => {
                    return <CrudMod edit="true" del="false" id={record.id} record={record}/>;
                }}/>
            </Datagrid>
        </ListMod>
    </div>
}