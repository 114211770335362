import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useRedirect} from "react-admin";

const useStyles = makeStyles({
    priorityLabelsWrap: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '16px',

        '& span': {
            fontFamily: `'Roboto', sans-serif`,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: '#7F8DA0',
        },

        '& span:first-child': {
            minWidth: '426px',
            width: '100%',
            marginRight: '8px'
        }
    },
    priorityWrap: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        margin: '0 0 8px',

        '& p': {
            display: 'block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',

            background: '#C1ADE1',
            borderRadius: '6px',
            padding: '6px 16px 6px 20px',
            margin: '0 8px 0 0',

            maxWidth: '426px',
            width: '100%',

            fontFamily: `'Roboto', sans-serif`,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: '#F8F2FF',
            cursor: 'pointer',

            '&:hover': {
                background: 'rgba(73, 37, 132, 0.8)',
                transition: 'background 0.3s ease-in-out'
            }
        },
        '& input': {
            background: '#F8F2FF',
            borderRadius: '6px',
            padding: '6px',
            width: '40px',
            border: 'none',
            outline: 'none',
            '-webkit-appearance': 'none',
            textAlign: 'center',

            fontFamily: `'Roboto', sans-serif`,
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            letterSpacing: '0.25px',
            color: '#25232B',

            '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: '0'
            }
        }
    },
});

export const Priorities = (props) => {
    const classes = useStyles();
    const redirect = useRedirect();

    const navigateToItem = (itemId) => {
        redirect(`${props.url}/${itemId}`)
    }

    return (
        <>
            <div className={classes.priorityLabelsWrap}>
                <span>Linked {props.title}</span>
                <span>Priority</span>
            </div>
            {
                props.data.map((item, i) => {
                    return (
                            <Fragment key={i}>
                                <div className={classes.priorityWrap}>
                                    <p title={item.name} onClick={() => navigateToItem(item.id)}>{item.name}</p>
                                    <input type="number" defaultValue={item.order}
                                           onChange={(e) => props.changeOrder(e, item.id)}/>
                                </div>
                            </Fragment>
                    );
                })
            }
        </>
    );
}