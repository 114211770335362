import GradientButton from "./GradientButton";
import PurpleBorderButton from "./PurpleBorderButton";
import React from "react";

const ModalButtons = ({onCancel, removeSubscription, onSubmit}) => {
    return (
        <div className="subscription-buttonsWrap">
            <span className="subscription-cancel-btn">
                <PurpleBorderButton label={'Cancel'} onClick={onCancel} />
            </span>

            <div className="btnsWrap">
                {removeSubscription}
                <GradientButton label={'Save'} onClick={onSubmit} />
            </div>
        </div>
    );
}

export default ModalButtons;