import * as React from "react";
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import inMemoryJWT from "../../Auth/inMemoryJwt";
import {Loader} from "./Loader";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    }
});

const MyAppBar = props => {
    const adminName = inMemoryJWT.getName();
    const adminEmail = inMemoryJWT.getEmail();
    const loading = useSelector((state) => (state.loader.isLoading));
    const classes = useStyles();
    return (
        <>
            <AppBar {...props} userMenu={<></>} button={<></>}>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                    id="react-admin-title"
                />
                <span className={classes.spacer} />
                <Typography
                    variant="h6"
                    color="inherit"
                    title={adminEmail}
                >{adminName}
                </Typography>
            </AppBar>
            {loading && <Loader />}
        </>
    );
};

export default MyAppBar;