import React from "react";
import {Datagrid, FunctionField, ReferenceManyField, TextField} from "react-admin";
import {convertedDateWithTimeGMT} from "../../../Functions/ConvertedDate";

const ReceiptValidationLogs = (props) => {
    const classes = props.classes;
    return (
        <>
            <h3 className={classes.title}>Receipt Validation Logs</h3>
            <div className={classes.listWrap}>
                <ReferenceManyField reference="purchase-logs/" perPage={50} target="customer_id" sort={{field: 'created_at', order: 'DESC'}}>
                    <Datagrid bulkActionButtons={false}>
                        <TextField label='Message' source="message" sortBy="message"/>
                        <FunctionField label='Created At' source="created_at" sortBy="created_at" render={(record) => {
                            return <span>{convertedDateWithTimeGMT(record.created_at)}</span>
                        }}/>
                    </Datagrid>
                </ReferenceManyField>
            </div>
        </>
    );
};

export default ReceiptValidationLogs;