import {
    Edit,
    required,
    TextInput,
    BooleanInput,
    Link,
    FunctionField,
    SimpleForm,
    TabbedShowLayout,
    Tab, useNotify, useDataProvider, useRefresh
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";
import WebinarLocalization from "./webinarLocalization";
import {Calendar} from "react-multi-date-picker";
import {convertedDate, convertedDateWithTime} from "../../../Functions/ConvertedDate";

export const EditWebinar = () => {
    const {id} = useParams();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const onDateChange = (e) => {
        if (e && e.value.unix) {
            const date = new Date(e.value.unix * 1000);
            formData.show_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        }
    }

    const [formData] = useState({
        name: '',
        show_date: '',
        subscription: false,
    });

    const editCreate = () => {
        if (!formData.name.length) {
            document.querySelector('.name').classList.add('error');

            return;
        }

        dispatch(setLoadStatus(true));
        dataProvider.update(`webinars/general`, {id: `${id}`, data: {
                name: formData.name,
                show_date: formData.show_date || document.getElementById('show_date').value,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
            }}).then(() => {
            dispatch(setLoadStatus(false));
            refresh(true);
            notify(`Webinar general update`);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                    <Link to="/webinars/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit Webinar</h2>
            <Edit title="Edit Webinar">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <FunctionField label="" render={(record) => {
                                formData.name = record.name;
                                return <>
                                    <TextInput className="formFields name" label="Name" source="name" fullWidth id="name" validate={required()} onChange={(e) => {
                                        nameChange(e)
                                    }} multiline/>
                                    <p className="required-text">Required</p>
                                </>;
                            }}/>
                            <span className="showDateLabel">Show Date</span>
                            <FunctionField source="show_date" render={(record) => {
                                return (
                                    <>
                                        <Calendar
                                            value={record.show_date ? convertedDateWithTime(record.show_date) : null}
                                            onPropsChange={(e) => {
                                                onDateChange(e)
                                            }}
                                            minDate="2000/01/01"
                                            maxDate="3000/12/31"
                                        />
                                        <input type="hidden" id="show_date"
                                               value={record.show_date && !formData.show_date ? convertedDate(record.show_date) : formData.show_date}/>
                                    </>
                                );
                            }}/>
                            <FunctionField label="" render={(record) => {
                                formData.subscription = record.subscription;
                                return <BooleanInput label="Subscription Required"
                                                     name="subscription" source="subscription"
                                                     className="booleanInput"
                                                     format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                     parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                     onChange={(e) => {
                                                         formData.subscription = e.target.checked
                                                     }}/>;
                            }}/>
                            <Save />
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <WebinarLocalization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <WebinarLocalization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <WebinarLocalization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}