import * as React from "react";
import {
    Create, Link, required,
    SimpleForm, TextInput, SaveButton, email, minLength, PasswordInput
} from 'react-admin';

export const CustomerCreate = () => {
    const Save = () => {
        return (
            <div className="customerActionToolbar">
                <Link to="/customers" className="button bar-purple-button">Cancel</Link>
                <SaveButton alwaysEnable label="Save" className="button bar-gradient-button" icon={<></>}/>
            </div>
        );
    }

    return (
        <>
            <h2 className="dailyTitle">New Customer</h2>
            <Create>
                <SimpleForm toolbar={<Save/>}>
                    <div className="customer-form-wrap">
                        <TextInput label="First Name" name="first_name" source="first_name" fullWidth id="first_name"
                                   className="formFields first-name" validate={required()} multiline/>
                        <TextInput label="Last Name" name="last_name" source="last_name" fullWidth id="last_name"
                                   className="formFields last-name" validate={required()} multiline/>
                        <TextInput label="Email" name="email" source="email" fullWidth id="email"
                                   className="formFields email" validate={[required(), email()]}/>
                        <PasswordInput label="Password" name="password" source="password" fullWidth id="password"
                                   className="formFields password" validate={[required(), minLength(8)]} initiallyVisible={false}/>
                    </div>
                </SimpleForm>
            </Create>
        </>
    )
}