import {
    Datagrid,
    ReferenceManyField,
    required,
    SimpleForm,
    TextField,
    TextInput,
    useDataProvider,
    useNotify,
    useRefresh,
    FunctionField
} from "react-admin";
import React, {useState} from "react";
import {TouchInput} from "../../../Functions/TouchInput";
import PaginationMod from "../../../Component/Redesign/PaginationMod";
import Modal from "../../../Component/Layouts/Modal/Modal";
import ModalButtons from "../../../Component/Layouts/Button/ModalButtons";
import GradientButton from "../../../Component/Layouts/Button/GradientButton";
import ConfirmModal from "../../../Component/Redesign/Confirm";
import {convertedDate} from "../../../Functions/ConvertedDate";

const Comments = (props) => {
    const classes = props.classes;
    const refresh = useRefresh();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const [openDeleteDialog, setDeleteDialogOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        title: '',
        content: <></>
    });

    const CommentForm = (action) => {
        const submit = (action) => {
            const comment = document.getElementById('comment');
            if (!comment.value) {
                TouchInput([comment]);
                return;
            }

            if (action.type === 'edit') {
                dataProvider.update(`comments`, {id: action.commentId, data: {comment: comment.value}}).then(() => {
                    handleCloseModal();
                    refresh();
                    notify('Comment created');
                })
            }

            if (action.type === 'create') {
                dataProvider.create(`comments/${props.customerId}`, {data: {comment: comment.value}}).then(() => {
                    handleCloseModal();
                    refresh();
                    notify('Comment created');
                })
            }
        }

        return (
            <SimpleForm toolbar={<></>}>
                <>
                    <TextInput id="comment" source="comment" defaultValue={action.comment} validate={required()}/>
                    <ModalButtons onCancel={handleCloseModal} onSubmit={() => {submit(action)}}/>
                </>
            </SimpleForm>
        );
    }

    const handleOpenModal = (modalType, commentId, comment) => {
        if (modalType === 'edit') {
            setModalData({title: 'Edit Comment', content: <CommentForm type={modalType} commentId={commentId} comment={comment}/>});
            setShowModal(true);
        }

        if (modalType === 'create') {
            setModalData({title: 'Add New Comment', content: <CommentForm type={modalType}/>});
            setShowModal(true);
        }
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setDeleteDialogOpen(false);
    }

    const handleOpenDeleteModal = () => {
        setDeleteDialogOpen(true);
    }

    const handleDialogClose = () => {
        setDeleteDialogOpen(false);
    }

    const handleConfirm = (commentId) => {
        dataProvider.delete('comments', {id: `${commentId}`}).then(() => {
            notify('Comment deleted');
            handleDialogClose();
            refresh();
        });
    }

    const Buttons = ({record}) => {
        return (
            <>
                <button className={`${classes.actionBtn} MuiButton-textPrimary`} onClick={() => handleOpenModal('edit', record.id, record.comment)}>
                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                         style={{fill: '#3f51b5', width: '20px', height: '20px'}}>
                        <path
                            d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                    </svg>
                </button>
                <button className={`${classes.actionBtn} MuiButton-textPrimary`}  onClick={handleOpenDeleteModal}>
                    <svg focusable="false" viewBox="0 0 24 24"
                         style={{fill: '#f44336', width: '20px', height: '20px'}}
                         aria-hidden="true">
                        <path
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
                    </svg>
                </button>
                <ConfirmModal
                    isOpen={openDeleteDialog}
                    title={`Delete comment`}
                    content={`Are you sure you want to delete comment?`}
                    onConfirm={() => handleConfirm(record.id)}
                    onClose={handleDialogClose}
                    type={'delete'}
                />
            </>
        );
    }

    return (
        <>
            <h3 className={classes.title}>Comments</h3>
            <div className={classes.addCommentBtnWrap}>
                <GradientButton label={'Add New Comment'} onClick={() => handleOpenModal('create')}/>
            </div>
            <div className={classes.listWrap}>
                <ReferenceManyField reference="comments/" target="customer_id" perPage={50} pagination={<PaginationMod />} sort={{field: 'updated_at', order: 'DESC'}}>
                    <Datagrid bulkActionButtons={false}>
                        <FunctionField label='Created At' source="created_at" sortBy="created_at" render={(record) => {
                            return <span>{convertedDate(record.created_at)}</span>
                        }}/>
                        <FunctionField label='Updated At' source="updated_at" sortBy="updated_at" render={(record) => {
                            return <span>{convertedDate(record.updated_at)}</span>
                        }}/>
                        <TextField label='Comment' source="comment" sortBy="comment"/>
                        <FunctionField label="" source="userDetails" render={(record) => {
                            return <Buttons record={record}/>;
                        }}/>
                    </Datagrid>
                </ReferenceManyField>
            </div>
            {showModal && (<Modal onClose={handleCloseModal} title={modalData.title} content={modalData.content}></Modal>)}
        </>
    );
}

export default Comments;