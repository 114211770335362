import React from 'react';
import {MenuItemLink, useLogout} from 'react-admin';
import {  Box } from '@material-ui/core';
import {MenuItem} from "@mui/material";
import MultilevelMenu from "./MultilevelMenu";

const CustomMenu = ({ onMenuClick, dense = false }) => {
    const logout = useLogout();

    const handleClick = () => logout();
    return (
        <Box mt={1}>
            <MenuItemLink
                to={{pathname: "/categories/general"}}
                primaryText='Categories'
                onClick={onMenuClick}
                dense={dense}
            />

            <MenuItemLink
                to={{pathname: "/products/general"}}
                primaryText='Products'
                onClick={onMenuClick}
                dense={dense}
            />

            <MenuItemLink
                to={{pathname: "/files/general"}}
                primaryText='Files'
                onClick={onMenuClick}
                dense={dense}
            />

            <MenuItemLink
                to={{pathname: "/daily-dares/general"}}
                primaryText='Daily Dare'
                onClick={onMenuClick}
                dense={dense}
            />

            <MenuItemLink
                to={{pathname: "/customers"}}
                primaryText='Customers'
                onClick={onMenuClick}
                dense={dense}
            />

            <MultilevelMenu
                title="Settings"
                routes={['/home', '/export']}
                menuItems={[
                    {
                        pathname: '/home/en',
                        primaryText: 'Home Blocks',
                        dense
                    },
                    {
                        pathname: '/export',
                        primaryText: 'Reports',
                        dense
                    },
                ]}
                onMenuClick={onMenuClick}
            />

            <MenuItemLink
                to={{pathname: "/academies/general"}}
                primaryText='Dare Academy'
                onClick={onMenuClick}
                dense={dense}
            />

            <MultilevelMenu
                title="Webinars"
                routes={['/webinars', '/webinar']}
                menuItems={[
                    {
                        pathname: '/webinars/general',
                        primaryText: 'Upcoming Webinar',
                        dense
                    },
                    {
                        pathname: '/webinar-histories/general',
                        primaryText: 'Webinar Histories',
                        dense
                    }
                ]}
                onMenuClick={onMenuClick}
            />

            <MenuItemLink
                to={{pathname: "/success-stories/general"}}
                primaryText='Success Stories'
                onClick={onMenuClick}
                dense={dense}
            />

            <MenuItemLink
                to={{pathname: "/daily-inspirations/general"}}
                primaryText='Daily Inspirations'
                onClick={onMenuClick}
                dense={dense}
            />

            <MenuItem
                onClick={handleClick}
            >
                <div className="logout-icon-wrap">
                    <svg className="logout-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path
                            d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
                    </svg>
                </div>
                Logout
            </MenuItem>
        </Box>
    );
}

export default CustomMenu;
