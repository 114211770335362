import React from 'react';
import {
    Datagrid,
    DateInput,
    TopToolbar,
    Filter,
    TextInput,
    FunctionField
} from 'react-admin';
import ListMod from "../../../Component/Redesign/ListMod";
import CrudMod from "../../../Component/Layouts/Button/CrudMod";
import CreateGradientButtonMod from "../../../Component/Layouts/Button/CreateGradientButtonMod";
import {makeStyles} from "@material-ui/core/styles";
import {convertedDate} from "../../../Functions/ConvertedDate";

const useStyles = makeStyles({
    customTable: {
        '& th span': {
            fontWeight: '500'
        },

        '& th, & td': {
            padding: '6px 8px',

            '&:nth-child(1)': {
                minWidth: '116px'
            },

            '&:nth-child(2)': {
                maxWidth: '300px',
                minWidth: '196px'
            },

            '&:nth-child(3)': {
                maxWidth: '600px',
                minWidth: '450px'
            },

            '&:nth-child(4)': {
                minWidth: '116px'
            },

            '&:nth-child(6) div': {
                justifyContent: 'flex-end'
            },

            '& .MuiButton-root': {
                minWidth: 'unset'
            }
        },

        '& th:nth-child(2) span, & th:nth-child(3) span': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
        },

        '& td:nth-child(2) span': {
            display: 'block',
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },

        '& td:nth-child(3) span': {
            maxWidth: '100%',
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': '3',
            '-webkit-box-orient': 'vertical',
            wordBreak: 'break-word'
        }
    }
});

const ListActions = () => {
    return (<TopToolbar>
        <CreateGradientButtonMod label={'Add New Daily Dare'} />
    </TopToolbar>);
};

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search" source="search" alwaysOn />
        <DateInput label="Search by show date" source="show_date" alwaysOn name="show_date"/>
    </Filter>
);

const TypesField = ({ record }) => {
    if(!record.types || !record.types.length){
        return '';
    }

    return record.types.map((item, i) => (
        <div key={i}>
            <span>{item.type}</span>
        </div>
    ))
};

const LocalsField = ({ record }) => {
    if(!record.locales || !record.locales.length){
        return '';
    }

    const lastElement = record.locales.length - 1;
    return record.locales.map((item, i) => (
        <span key={i}>{item.locale}{i === lastElement ? '' :  ', '}</span>
    ))
};

export const DailyDareGeneralList = ({permissions, ...props}) => {
    const classes = useStyles();
    return (
        <div className="customTableWrap">
            <ListMod {...props}
                     title="Daily Dare"
                     filters={<Filters />}
                     actions={<ListActions />}
                     sort={{field: 'show_date', order: 'DESC'}}
            >
                <Datagrid bulkActionButtons={false} className={classes.customTable}>
                    <FunctionField label="Show Date" source="show_date" render={(record) => {
                        if (!record.show_date) return <></>;
                        return (convertedDate(record.show_date));
                    }}/>
                    <FunctionField label='Name' source="name" sortBy="name" render={(record) => {
                        return (
                            <span title={record.name}>{record.name}</span>
                        );
                    }}/>
                    <FunctionField label='Description' source="description" sortBy="description" render={(record) => {
                        return (
                            <span title={record.description}>{record.description}</span>
                        );
                    }}/>
                    <FunctionField label='Type' source="types" sortable={false} render={(record) => {
                        return <TypesField record={record}/>;
                    }}/>
                    <FunctionField label='Localization' source="locales" sortable={false} render={(record) => {
                        return <LocalsField record={record} />;
                    }}/>
                    <FunctionField label="" source="actions" render={(record) => {
                        return <CrudMod edit="true" del="true" id={record.id} record={record}/>;
                    }}/>
                </Datagrid>
            </ListMod>
        </div>
    )
};
