import {
    TextInput,
    required,
    SimpleForm,
    useCreate,
    useNotify,
    useDataProvider,
    Link,
    FunctionField,
    SelectInput,
    useRedirect
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {LocalizationActions} from "../../../Component/Layouts/LocalizationActions";
import {getFileDurationFromTimestamp} from "../../../Functions/ConvertFileDuration";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setLoadStatus} from "../../../Store/LoaderReducer";
import {handleDownload} from "../../../Functions/DownloadFile";
import {TouchInput} from "../../../Functions/TouchInput";

const LOCALIZATION_DATA = {
    file_duration: 0,
    file_original_name: '',
    file_type: 4,
    file: '',
    id: '',
    image_url: '',
    link: '',
    name: ''
}

const DailyInspirationLocalization = () => {
    const {'*': locale, id} = useParams();
    const initialRef = useRef(true);
    const audioRef = useRef(null);
    const videoRef = useRef(null);
    const [localizationData, setLocalizationData] = useState({[locale]: LOCALIZATION_DATA});
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const [fileDurationByLink, setFileDurationByLink] = useState(0)
    const [fileLink, setFileLink] = useState('')

    const [fileType, setFileType] = useState(4);
    const [fileDuration, setFileDuration] = useState(0);
    const [fileDurationValue, setFileDurationValue] = useState(null);
    const [file, setFile] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        dataProvider.getOne('daily-inspirations', {id: `${id}/${locale}`}).then((res) => {
            setLocalizationData({[locale]: LOCALIZATION_DATA});
            localizationData[locale].name = '';
            localizationData[locale].link = '';
            localizationData[locale].file_type = 4;
            if (res.data.id !== 0) {
                setLocalizationData({[locale]: res.data});
                setFileType(res.data.file_type);

                if (res.data.link) {
                    setFileLink(res.data.link);
                }
            }
        });
    }, [dataProvider, id, locale, localizationData])

    useEffect(() => {
        setFileDurationByLink(0)
        setFileDuration(0)
        localizationData[locale].link = ''
        localizationData[locale].file_duration = 0

        if (fileLink) {
            localizationData[locale].link = fileLink
        }
    }, [fileLink, locale, localizationData])

    const fileLocalizationCreate = () => {
        const dailyName = document.getElementById(`fileName_${locale}`);
        const dailyLink = document.getElementById(`fileLink_${locale}`);
        const dailyFile = document.querySelector(`.file_${locale}`);
        const dailyFileDuration = document.querySelector(`.fileDuration_${locale}`);
        const dailyFileType = document.getElementById(`fileType_${locale}`);
        const dailyImage = document.getElementById(`image_${locale}`);

        const dailyRequiredFields = document.querySelectorAll('.daily_required div.file-validation-wrap');
        const dailyRequiredError = document.querySelectorAll('.daily_required p');

        const dailyRequiredLink = document.querySelector('.form-field.link.daily');
        const dailyRequiredImageFields = document.querySelectorAll('.daily_image_required div');
        const dailyRequiredImageError = document.querySelectorAll('.daily_image_required p');

        if ((+dailyFileType.nextElementSibling.value === 1 || +dailyFileType.nextElementSibling.value === 2) && (!dailyFile || !dailyFile.value)) {
            dailyRequiredFields.forEach((item) => {
                item.classList.add('error-field');
            });

            dailyRequiredError.forEach((item) => {
                item.classList.add('errorText');
                item.removeAttribute('style')
            });

            notify('Daily Inspiration Error');
            return;
        }

        if ((+dailyFileType.nextElementSibling.value === 4 || +dailyFileType.nextElementSibling.value === 3) && !dailyLink.value) {
            TouchInput([dailyLink]);

            dailyRequiredLink.classList.add('error');
        }

        if (!dailyImage.value) {
            if (!dailyImage.value.length) {
                dailyRequiredImageFields.forEach((item) => {
                    item.classList.add('error-field');
                })
                dailyRequiredImageError.forEach((item) => {
                    item.classList.add('errorText');
                    item.removeAttribute('style')
                })
            }

            notify('Daily Inspiration Error');
            return;
        }

        const formData = {
            'name': dailyName.value,
            'link': dailyLink ? dailyLink.value : '',
            'file': dailyFile && dailyFile.nextElementSibling.files[0] ? dailyFile.nextElementSibling.files[0] : '',
            'file_duration': dailyFileDuration ? dailyFileDuration.value : 0,
            'file_type': dailyFileType ? dailyFileType.nextElementSibling.value : 4,
            'image': dailyImage.nextElementSibling.files[0] ? dailyImage.nextElementSibling.files[0] : dailyImage.value,
        };


        dispatch(setLoadStatus(true));
        if (!localizationData[locale].id) {
            create(`daily-inspirations/${id}/${locale}`, {
                data: {
                    name: formData.name,
                    link: formData.link,
                    file: formData.file,
                    file_duration: formData.file_duration,
                    file_type: fileType,
                    image: formData.image
                }
            }, {
                onSuccess: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(`Daily Inspiration localization ${locale} created`);
                    redirect(`/daily-inspirations/general/${id}`);
                },
                onError: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(res.message, 'warning');
                }
            });
        }

        if (localizationData[locale].id) {
            dataProvider.update(`daily-inspirations`, {
                id: `${id}/${locale}`, data: {
                    name: formData.name,
                    link: formData.link,
                    file: formData.file,
                    file_duration: formData.file_duration,
                    file_type: fileType,
                    image: formData.image
                }
            }).then(() => {
                dispatch(setLoadStatus(false));
                notify(`Daily Inspiration localization ${locale} update`);
            }).catch((res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            });
        }
    }

    const Save = ({...props}) => {
        return (
            <div className="dailyActionToolbar">
                {props.localizationId &&
                    <LocalizationActions id={id} status={props.localizationStatus} url={'daily-inspirations'}
                                         redirectUrl={'/daily-inspirations/general'} prefix={locale}/>
                }
                <Link to="/daily-inspirations/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={fileLocalizationCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const getFile = (e, durationElement) => {
        let file = e.currentTarget.files[0];
        if (!file || fileType === 4 || fileType === 3) {
            return;
        }

        const mimeType = file.type;

        const regex = fileType === 1 ? /audio\/mpeg/ : /video\/mp4/;
        const acceptTypes = fileType === 1 ? '.mp3' : '.mp4';
        if (!mimeType.match(regex)) {
            notify(`File should be in (${acceptTypes}) format.`, 'warning');
            file = null;
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContext.decodeAudioData(event.target.result, (buffer) => {
                const hours = Math.floor(buffer.duration / 3600);
                const minutes = Math.floor((buffer.duration % 3600) / 60);
                const seconds = Math.floor(buffer.duration % 60);

                const fileRequiredFields = document.querySelectorAll('.daily_required div');
                const fileRequiredError = document.querySelectorAll('.daily_required p');

                const duration = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

                fileRequiredFields.forEach((item) => {
                    item.className = '';
                });

                fileRequiredError.forEach((item) => {
                    item.className = '';
                    item.style.display = 'none';
                });

                setFile(file);
                setFileDuration(duration);
                setFileDurationValue(Math.floor(buffer.duration * 1000));
            });


        };
        reader.onerror = (event) => {
            console.error("An error occurred reading the file: ", event);
        };
        reader.readAsArrayBuffer(file);
    }

    const getFileType = (e) => {
        if (!e) {
            setFileType(e.target.value);
            localizationData[locale].file_type = 4;
        }

        setFile('');
        localizationData[locale].file_duration = 0;
        localizationData[locale].file_original_name = '';
        localizationData[locale].file_url = '';
        setFileDuration(0);
        setFileDurationValue(null);
        setFileType(e.target.value);
        localizationData[locale].file_type = e.target.value;
    }

    const uploadImage = (e, imageElement) => {
        const file = e.currentTarget.files[0];
        if (!file) {
            return;
        }

        const mimeType = file.type;
        const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!mimetypes.includes(mimeType)) {
            notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
            return;
        }

        const fileRequiredImageFields = document.querySelectorAll('.daily_image_required div');
        const fileRequiredImageError = document.querySelectorAll('.daily_image_required p');

        const reader = new FileReader();

        reader.onload = () => {
            fileRequiredImageFields.forEach((item) => {
                item.className = '';
            });

            fileRequiredImageError.forEach((item) => {
                item.className = '';
                item.style.display = 'none';
            });

            setImageSrc(reader.result);
        }

        reader.onerror = (event) => {
            console.error("An error occurred reading the file: ", event);
        };
        reader.readAsDataURL(file);
    }

    const onInputChange = (className) => {
        if (document.querySelector(className).classList.contains('error')) {
            document.querySelector(className).classList.remove('error');
        }
    }

    const handleAudioLoadedMetadata = () => {
        if (audioRef && audioRef.current) {
            const duration = audioRef.current.duration ? audioRef.current.duration * 1000 : 0;
            setFileDurationByLink(duration)
        } else {
            setFileDurationByLink(0)
        }
    }

    const handleLoadedMetadata = (e) => {
        if (videoRef && videoRef.current) {
            const duration = videoRef.current.duration ? videoRef.current.duration * 1000 : 0;
            setFileDurationByLink(duration)
        } else {
            setFileDurationByLink(0)
        }
    }

    return (
        <SimpleForm record={localizationData[locale]} toolbar={<></>}>
            <div className="files-localization-form">
                <TextInput
                    label="Name"
                    className="form-field name daily"
                    name="name"
                    source="name"
                    id={`fileName_${locale}`}
                    defaultValue={localizationData[locale].name}
                    onChange={(e) => {
                        localizationData[locale].name = e.target.value;
                        onInputChange('.form-field.name.daily')
                    }}
                    multiline
                    fullWidth
                />

                <SelectInput
                    label="File Type"
                    name="file_type"
                    source="file_type"
                    id={`fileType_${locale}`}
                    defaultValue={localizationData[locale].file_type}
                    choices={[
                        {id: 4, name: 'Link Video'},
                        {id: 3, name: 'Link Audio'},
                        {id: 2, name: 'Video'},
                        {id: 1, name: 'Audio'},
                    ]}
                    onChange={(e) => getFileType(e)}
                    validate={required()}
                    fullWidth/>

                {(fileType === 3 || fileType === 4) &&
                    <>
                        <TextInput
                            label="Link"
                            className="form-field link daily"
                            name="link"
                            source="link"
                            id={`fileLink_${locale}`}
                            defaultValue={localizationData[locale].link}
                            onChange={(e) => {
                                setFileLink(e.target.value)
                                onInputChange('.form-field.link.daily')
                            }}
                            validate={required()}
                            multiline
                            fullWidth
                        />
                        <p className="required-text">Required</p>
                        {fileType === 3 ?
                            <>
                                <audio className="audio-plug" ref={audioRef} src={fileLink} autoPlay={false} onLoadedMetadata={handleAudioLoadedMetadata}></audio>
                                <div className="fileField form-field">
                                    <div className="file-validation-wrap">
                                        <div className="file-field-wrap">
                                            <span className="fieldTitle">File Duration</span>
                                            <label htmlFor={`videoDuration_${locale}`}></label>
                                            <FunctionField label="" source="file_duration" render={() => {
                                                let duration;
                                                if (localizationData[locale].file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].file_duration);
                                                return (
                                                    <input
                                                        id={`audioDuration_${locale}`}
                                                        className="durationInput"
                                                        name={`file_duration.en`}
                                                        value={duration && fileLink ? duration : getFileDurationFromTimestamp(fileDurationByLink)}
                                                        placeholder={'00:00:00'}
                                                        readOnly={true}
                                                    />
                                                );
                                            }}/>
                                            <input type="hidden" className={`fileDuration_${locale}`}
                                                   value={localizationData[locale].file_duration ? localizationData[locale].file_duration : fileDurationByLink}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null }

                        {fileType === 4 ?
                            <>
                                <video className="video-plug" ref={videoRef} src={fileLink} autoPlay={false} onLoadedMetadata={handleLoadedMetadata}></video>
                                <div className="fileField form-field">
                                    <div className="file-validation-wrap">
                                        <div className="file-field-wrap">
                                            <span className="fieldTitle">File Duration</span>
                                            <label htmlFor={`videoDuration_${locale}`}></label>
                                            <FunctionField label="" source="file_duration" render={() => {
                                                let duration;
                                                if (localizationData[locale].file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].file_duration);

                                                return (
                                                    <input
                                                        id={`audioDuration_${locale}`}
                                                        className="durationInput"
                                                        name={`file_duration.en`}
                                                        value={duration && fileLink ? duration : getFileDurationFromTimestamp(fileDurationByLink)}
                                                        placeholder={'00:00:00'}
                                                        readOnly={true}
                                                    />
                                                );
                                            }}/>
                                            <input type="hidden" className={`fileDuration_${locale}`}
                                                   value={localizationData[locale].file_duration ? localizationData[locale].file_duration : fileDurationByLink}/>
                                        </div>
                                    </div>
                                </div>
                            </>
                            : null}
                    </>
                }

                {fileType === 1 &&
                    <>
                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File</span>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor={`audioFile_${locale}`} className="fileName">
                                            {!file && localizationData[locale].file_url &&
                                                <span>{localizationData[locale].file_original_name}</span>
                                            }
                                            {file && <span>{file.name}</span>}
                                        </label>
                                        <input
                                            type="hidden"
                                            className={`file_${locale}`}
                                            name={`file.en`}
                                            value={file ? file : localizationData[locale].file_url}
                                        />
                                        <input
                                            type="file"
                                            className="fileInput"
                                            id={`audioFile_${locale}`}
                                            accept=".mp3,audio/*"
                                            onChange={(e) => getFile(e, 'audioDuration')}
                                        />
                                        <label htmlFor={`audioFile_${locale}`} className="uploadButton">
                                            {file || localizationData[locale].file_url ? 'Update a File' : 'Upload a File'}
                                        </label>
                                        {localizationData[locale].file_url && <button type="button" className="uploadButton download-btn" onClick={() => handleDownload(localizationData[locale].file_original_name, localizationData[locale].file_url)}>
                                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#FFF"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#FFF"/>
                                            </svg>
                                            Download file
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>

                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File Duration</span>
                                    <label htmlFor={`audioDuration_${locale}`}></label>
                                    <FunctionField label="" source="file_duration" render={() => {
                                        let duration;
                                        if (localizationData[locale].file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].file_duration);

                                        return (
                                            <input
                                                id={`audioDuration_${locale}`}
                                                className="durationInput"
                                                name={`file_duration.en`}
                                                value={fileDuration ? fileDuration : duration}
                                                placeholder={'00:00:00'}
                                                readOnly={true}
                                            />
                                        );
                                    }}/>
                                    <input type="hidden" className={`fileDuration_${locale}`}
                                           value={fileDurationValue ? fileDurationValue : localizationData[locale].file_duration}/>

                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>
                    </>
                }

                {fileType === 2 &&
                    <>
                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File</span>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor={`videoFile_${locale}`} className="fileName">
                                            {!file && localizationData[locale].file_url &&
                                                <span>{localizationData[locale].file_original_name}</span>
                                            }
                                            {file &&
                                                <span>{file.name}</span>
                                            }
                                        </label>
                                        <input
                                            type="hidden"
                                            className={`file_${locale}`}
                                            name={`file.en`}
                                            value={file ? file : localizationData[locale].file_url}
                                        />
                                        <input
                                            type="file"
                                            className="fileInput"
                                            id={`videoFile_${locale}`}
                                            accept="video/mp4,video/x-m4v,video/*"
                                            onChange={(e) => getFile(e, 'videoDuration')}
                                        />
                                        <label htmlFor={`videoFile_${locale}`} className="uploadButton">
                                            {file || localizationData[locale].file_url ? 'Update a File' : 'Upload a File'}
                                        </label>
                                        {localizationData[locale].file_url && <button type="button" className="uploadButton download-btn" onClick={() => handleDownload(localizationData[locale].file_original_name, localizationData[locale].file_url)}>
                                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#FFF"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#FFF"/>
                                            </svg>
                                            Download file
                                        </button>}
                                    </div>
                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>

                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File Duration</span>
                                    <label htmlFor={`videoDuration_${locale}`}></label>
                                    <FunctionField label="" source="file_duration" render={() => {
                                        let duration;
                                        if (localizationData[locale].file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].file_duration);

                                        return (
                                            <input
                                                id={`audioDuration_${locale}`}
                                                className="durationInput"
                                                name={`file_duration.en`}
                                                value={fileDuration ? fileDuration : duration}
                                                placeholder={'00:00:00'}
                                                readOnly={true}
                                            />
                                        );
                                    }}/>
                                    <input type="hidden" className={`fileDuration_${locale}`}
                                           value={fileDurationValue ? fileDurationValue : localizationData[locale].file_duration}/>
                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>
                    </>
                }

                <div className="form-field daily_image_required">
                    <span className="fieldTitle">Image</span>
                    <div>
                        <div className="imageWrap"
                             style={{height: imageSrc || localizationData[locale].image_url ? '194px' : 'fit-content'}}>
                            <img
                                className="image"
                                id={`image_preview_${locale}`}
                                src={imageSrc ? imageSrc : localizationData[locale].image_url}
                                alt=""
                            />
                            <p style={{display: 'none'}}>Required</p>
                        </div>
                        <label className="imageLabel" htmlFor={`imageFile_${locale}`}></label>
                        <input
                            type="hidden"
                            id={`image_${locale}`}
                            name="image.en"
                            value={imageSrc ? imageSrc : localizationData[locale].image_url}
                        />
                        <input
                            type="file"
                            id={`imageFile_${locale}`}
                            className="fileInput"
                            name="image.en"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => uploadImage(e)}
                        />
                        <label htmlFor={`imageFile_${locale}`} className="uploadButton">
                            {imageSrc || localizationData[locale].image_url ? 'Update a File' : 'Upload a File'}
                        </label>
                    </div>
                </div>
            </div>
            <Save localizationId={localizationData[locale].id} localizationStatus={localizationData[locale].status}/>
        </SimpleForm>
    );
}

export default DailyInspirationLocalization;