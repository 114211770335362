import {useDataProvider, useNotify, useRedirect} from "react-admin";
import * as React from "react";
import {useState} from "react";
import ConfirmModal from "../Redesign/Confirm";

export const LocalizationActions = (props) => {
    const prefix = props.prefix;
    const dataProvider = useDataProvider();
    const redirect = useRedirect();
    const notify = useNotify();

    const [openDeleteDialog, setDeleteDialogOpen] = useState(false);
    const [openDisableDialog, setDisableDialogOpen] = useState(false);
    const [openEnableDialog, setEnableDialogOpen] = useState(false);

    const handleClick = (dialogType) => {
        if (dialogType === 'delete') {
            setDeleteDialogOpen(true);
        }

        if (dialogType === 'disable') {
            setDisableDialogOpen(true);
        }

        if (dialogType === 'enable') {
            setEnableDialogOpen(true);
        }
    }

    const handleDialogClose = (dialogType) => {
        if (dialogType === 'delete') {
            setDeleteDialogOpen(false);
        }

        if (dialogType === 'disable') {
            setDisableDialogOpen(false);
        }

        if (dialogType === 'enable') {
            setEnableDialogOpen(false);
        }
    };

    const handleConfirm = (dialogType) => {
        if (dialogType === 'delete') {
            dataProvider.delete(`${props.url}`, {id: `${props.id}/${prefix}`}).then(() => {
                setDeleteDialogOpen(false);
                redirect(`${props.redirectUrl}/${props.id}`);
            }).catch((err) => {
                setDeleteDialogOpen(false);
                notify(err.message, {type: 'warning'});
            });
        }

        if (dialogType === 'disable') {
            dataProvider.update(`${props.url}`, {id: `${props.id}/${prefix}/disable`}).then(() => {
                setDisableDialogOpen(false);
                redirect(`${props.redirectUrl}/${props.id}`);
            }).catch((err) => {
                setDisableDialogOpen(false);
                notify(err.message, {type: 'warning'});

            });
        }

        if (dialogType === 'enable') {
            dataProvider.update(`${props.url}`, {id: `${props.id}/${prefix}/enable`}).then(() => {
                setEnableDialogOpen(false);
                redirect(`${props.redirectUrl}/${props.id}`);
            }).catch((err) => {
                setEnableDialogOpen(false);
                notify(err.message, {type: 'warning'});

            });
        }
    };

    return (
        <>
            <div className="localizationButtons">
                <button className="localizationButton delete" type="button" onClick={() => handleClick('delete')}>Delete Localization</button>
                <ConfirmModal isOpen={openDeleteDialog}
                              title={`Delete ${prefix} localization`}
                              content={`Are you sure you want to delete ${prefix} localization?`}
                              onConfirm={() => {handleConfirm('delete')}}
                              onClose={() => {handleDialogClose('delete')}} type={'delete'}/>
                {props.status === 1 &&
                    <>
                        <button className="localizationButton" type="button" onClick={() => handleClick('disable')}>Disable Localization</button>
                        <ConfirmModal
                            isOpen={openDisableDialog}
                            title={`Disable ${prefix} localization`}
                            content={`Are you sure you want to disable ${prefix} localization?`}
                            onConfirm={() => {handleConfirm('disable')}}
                            onClose={() => {handleDialogClose('disable')}}
                            type={'disable'}
                        />
                    </>
                }
                {props.status === 0 &&
                    <>
                        <button className="localizationButton enable" type="button" onClick={() => handleClick('enable')}>Enable Localization</button>
                        <ConfirmModal
                            isOpen={openEnableDialog}
                            title={`Enable ${prefix} localization`}
                            content={`Are you sure you want to Enable ${prefix} localization?`}
                            onConfirm={() => {handleConfirm('enable')}}
                            onClose={() => {handleDialogClose('enable')}}
                            type={'enable'}
                        />
                    </>
                }
            </div>
        </>
    );
}