import {Link, useDataProvider, useNotify, useRedirect, useRefresh} from "react-admin";
import * as React from "react";
import {TouchInput} from "../../../../Functions/TouchInput";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../../Store/LoaderReducer";

export const DailyLocalizationActions = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();
    const refresh = useRefresh()

    const id = props.id;

    const onSubmit = () => {

        const dailyName = document.getElementById(`dailyName_${props.localization}`);
        const dailyFile = document.querySelector(`.dailyFile_${props.localization}`);
        const dailyFileDuration = document.querySelector(`.dailyFileDuration_${props.localization}`);
        const dailyFileType = document.getElementById(`dailyFileType_${props.localization}`);
        const dailyDescription = document.getElementById(`dailyDescription_${props.localization}`);
        const dailyImage = document.getElementById(`dailyImage_${props.localization}`);

        const windName = document.getElementById(`windName_${props.localization}`);
        const windFile = document.querySelector(`.windFile_${props.localization}`);
        const windFileDuration = document.querySelector(`.windFileDuration_${props.localization}`);
        const windFileType = document.getElementById(`windFileType_${props.localization}`);
        const windDescription = document.getElementById(`windDescription_${props.localization}`);
        const windImage = document.getElementById(`windImage_${props.localization}`);

        const dailyRequiredName = document.querySelector('.form-field.name.daily');
        const windRequiredName = document.querySelector('.form-field.name.wind');
        const dailyRequiredDescription = document.querySelector('.form-field.description.daily');
        const windRequiredDescription = document.querySelector('.form-field.description.wind');

        const dailyRequiredFields = document.querySelectorAll('.daily_required div.file-validation-wrap');
        const dailyRequiredError = document.querySelectorAll('.daily_required p');
        const windRequiredFields = document.querySelectorAll('.wind_required div.file-validation-wrap');
        const windRequiredError = document.querySelectorAll('.wind_required p');

        const dailyRequiredImageFields = document.querySelectorAll('.daily_image_required div');
        const dailyRequiredImageError = document.querySelectorAll('.daily_image_required p');
        const windRequiredImageFields = document.querySelectorAll('.wind_image_required div');
        const windRequiredImageError = document.querySelectorAll('.wind_image_required p');

        if (
            !dailyName.value || !dailyFile.value || !dailyFileDuration.value || !dailyFileType.nextElementSibling.value || !dailyDescription.value || !dailyImage.value
            || !windName.value || !windFile.value || !windFileDuration.value || !windFileType.nextElementSibling.value || !windDescription.value || !windImage.value
        ) {
            TouchInput([dailyName, windName, dailyDescription, windDescription]);

            if (!dailyName.value) {
                dailyRequiredName.classList.add('error');
            }

            if (!windName.value) {
                windRequiredName.classList.add('error');
            }

            if (!dailyDescription.value) {
                dailyRequiredDescription.classList.add('error');
            }

            if (!windDescription.value) {
                windRequiredDescription.classList.add('error');
            }

            if (!dailyFile.value.length) {
                dailyRequiredFields.forEach((item) => {
                    item.classList.add('error-field');
                })
                dailyRequiredError.forEach((item) => {
                    item.classList.add('errorText');
                    item.removeAttribute('style')
                })
            }

            if (!windFile.value.length) {
                windRequiredFields.forEach((item) => {
                    item.classList.add('error-field');
                })
                windRequiredError.forEach((item) => {
                    item.classList.add('errorText');
                    item.removeAttribute('style')
                })
            }

            if (!dailyImage.value.length) {
                dailyRequiredImageFields.forEach((item) => {
                    item.classList.add('error-field');
                })
                dailyRequiredImageError.forEach((item) => {
                    item.classList.add('errorText');
                    item.removeAttribute('style')
                })
            }

            if (!windImage.value.length) {
                windRequiredImageFields.forEach((item) => {
                    item.classList.add('error-field');
                })
                windRequiredImageError.forEach((item) => {
                    item.classList.add('errorText');
                    item.removeAttribute('style')
                })
            }

            notify('Daily Dare EN General Error');
            return;
        }

        const formData = {
            'daily_dare[name]': dailyName.value,
            'daily_dare_file': dailyFile.nextElementSibling.files[0] ? dailyFile.nextElementSibling.files[0] : dailyFile.value,
            'daily_dare[file_duration]': dailyFileDuration.value,
            'daily_dare[file_type]': +dailyFileType.nextElementSibling.value,
            'daily_dare[description]': dailyDescription.value,
            'daily_dare_image': dailyImage.nextElementSibling.files[0] ? dailyImage.nextElementSibling.files[0] : dailyImage.value,
            'wind_down[name]': windName.value,
            'wind_down_file': windFile.nextElementSibling.files[0] ? windFile.nextElementSibling.files[0] : windFile.value,
            'wind_down[file_duration]': windFileDuration.value,
            'wind_down[file_type]': +windFileType.nextElementSibling.value,
            'wind_down[description]': windDescription.value,
            'wind_down_image': windImage.nextElementSibling.files[0] ? windImage.nextElementSibling.files[0] : windImage.value
        };

        if (props.request === 'create') {
            dispatch(setLoadStatus(true));
            dataProvider.create(`daily-dares/${id}/${props.localization}`, {data: formData}).then(() => {
                notify(`Daily Dare General ${props.localization} created`);
                dispatch(setLoadStatus(false));
                redirect(`/daily-dares/general/${id}`);
                refresh()
            }).catch((err) => {
                notify(err.message, {type: 'error'});
                dispatch(setLoadStatus(false));
            });
        }

        if (props.request === 'edit') {
            dispatch(setLoadStatus(true));
            dataProvider.update(`daily-dares`, {id: `${props.id}/${props.localization}`, data: formData}).then(() => {
                notify(`Daily Dare General ${props.localization} updated`);
                dispatch(setLoadStatus(false));
                redirect(`/daily-dares/general/${id}`);
                refresh()
            }).catch((err) => {
                notify(err.message, {type: 'error'});
                dispatch(setLoadStatus(false));
            });
        }
    };

    return (
        <div className="dailyActionToolbar">
            <>
                <Link to="/daily-dares/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={onSubmit}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </>
        </div>
    );
};