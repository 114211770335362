import React from "react";
import {Datagrid, Filter, FunctionField, SelectField, TextField, TextInput, useDataProvider} from "react-admin";
import ListMod from "../../Component/Redesign/ListMod";
import {REPORT_TYPE_OF_PROJECT} from "../../Const/SelectionChoices";
import {GenerateReportForm} from "./components/generateReportForm";
import {ReportStatus} from "./components/ReportStatus";
import {Button} from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import {convertedDateWithTimeGMT} from "../../Functions/ConvertedDate";

const Filters = () => (<Filter>
    <TextInput variant="filled" label="Search" source="search" alwaysOn/>
</Filter>);


export const ReportList = () => {

    const dataProvider = useDataProvider()

    const handleDownload = (id) => {
        dataProvider.uploadFile(`export/${id}/download`)
    }

    return (<>
        <GenerateReportForm/>
        <div className="customTableWrap">
            <ListMod filters={<Filters/>} actions={false} sort={{field: 'date', order: 'DESC'}}>
                <Datagrid bulkActionButtons={false}>
                    <FunctionField label="Date (GMT)" source="date" sortBy="date"
                                   render={({date}) => date ? convertedDateWithTimeGMT(date) : ''}/>
                    <TextField label="Title" source="title"/>
                    <SelectField label="Type of Report" source="type" choices={REPORT_TYPE_OF_PROJECT}/>
                    <ReportStatus label="Status" source="status" sortable={false}/>
                    <FunctionField render={record =>
                        record.status === 3 &&
                        <Button onClick={() => handleDownload(record.id)}>
                            <FileDownloadOutlinedIcon/>
                        </Button>
                    }/>
                </Datagrid>
            </ListMod>
        </div>
    </>)
}
