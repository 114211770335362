import React from "react";
import {Datagrid, FunctionField, ReferenceManyField, SelectField, TextField} from "react-admin";
import {convertedDateWithTimeGMT} from "../../../Functions/ConvertedDate";
import PaginationMod from "../../../Component/Redesign/PaginationMod";

const Purchases = (props) => {
    const classes = props.classes;

    return (
        <>
            <div className={classes.listWrap}>
                <ReferenceManyField reference="purchases/" target="customer_id" perPage={50} pagination={<PaginationMod />} sort={{field: 'updated_at', order: 'DESC'}}>
                    <Datagrid bulkActionButtons={false}>
                        <FunctionField label='Date (GMT)' source="updated_at" sortBy="updated_at" render={(record) => {
                            return <span>{convertedDateWithTimeGMT(record.updated_at)}</span>
                        }}/>
                        <TextField label='Product ID' source="product" sortBy="product"/>
                        <SelectField source="verified" label="Verified" choices={[
                            {id: 0, name: 'No'},
                            {id: 1, name: 'Yes'}
                        ]}/>
                        <SelectField source="platform" label="Platform" choices={[
                            {id: null, name: '-'},
                            {id: 1, name: 'Android'},
                            {id: 2, name: 'iOS'}
                        ]}/>
                        <SelectField source="trial" label="Trial" choices={[
                            {id: 0, name: 'No'},
                            {id: 1, name: 'Yes'}
                        ]}/>
                        <FunctionField label="Notification Type" source="notification_type" render={(record) => {
                            return (record.notification_type ? record.notification_type : '-');
                        }}/>
                        <SelectField source="submitted_by_admin" label="Submitted By Admin" choices={[
                            {id: 0, name: 'No'},
                            {id: 1, name: 'Yes'}
                        ]}/>
                    </Datagrid>
                </ReferenceManyField>
            </div>
        </>
    );
};

export default Purchases;