import {REPORT_STATUS} from "../../../Const/SelectionChoices";
import {Typography} from "@mui/material";
import {useRecordContext} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    reportStatus: {
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: '#FFFFFF',
        borderRadius: '100px',
        padding: '6px 16px',
        '&.report-status-generated': {
            backgroundColor: '#4884EF'
        },
        '&.report-status-error': {
            backgroundColor: '#EB5757'
        },
        '&.report-status-pending': {
            backgroundColor: '#f3c002'
        },
        '&.report-status-processing': {
            backgroundColor: '#32A232'
        }
    }
})

export const ReportStatus = ({source}) => {
    const record = useRecordContext();
    const status = record[source];
    const classes = useStyles();

    if (status === undefined) {
        return null;
    }

    const item = REPORT_STATUS.find(st => st.id === status);

    if (!item) {
        return null;
    }

    return (
        <Typography
            component="span"
            variant="body2"
            className={`${classes.reportStatus} report-status-${item.name.toLowerCase()}`}>
            {item.name}
        </Typography>
    )
}
