import {
    TextInput,
    required,
    SimpleForm,
    useCreate,
    useNotify,
    useDataProvider,
    Link,
    FunctionField,
    SelectInput,
    useRedirect
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {LocalizationActions} from "../../../Component/Layouts/LocalizationActions";
import {getFileDurationFromTimestamp} from "../../../Functions/ConvertFileDuration";
import {useParams} from "react-router-dom";
import {TouchInput} from "../../../Functions/TouchInput";
import {useDispatch} from "react-redux";
import {setLoadStatus} from "../../../Store/LoaderReducer";

const LOCALIZATION_DATA = {
    description: '',
    file_duration: 0,
    file_original_name: '',
    file_type: -1,
    file: '',
    id: '',
    image_url: '',
    name: ''
}

const SuccessStoryLocalization = () => {
    const {'*': locale, id} = useParams();
    const initialRef = useRef(true);
    const [localizationData, setLocalizationData] = useState({[locale]: LOCALIZATION_DATA});
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const [fileType, setFileType] = useState(-1);
    const [fileDuration, setFileDuration] = useState(0);
    const [fileDurationValue, setFileDurationValue] = useState(null);
    const [file, setFile] = useState('');
    const [imageSrc, setImageSrc] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        dataProvider.getOne('success-stories', {id: `${id}/${locale}`}).then((res) => {
            setLocalizationData({[locale]: LOCALIZATION_DATA});
            localizationData[locale].name = '';
            localizationData[locale].description = '';
            localizationData[locale].file_type = -1;
            if (res.data.id !== 0) {
                setLocalizationData({[locale]: res.data});
                setFileType(res.data.file_type);
            }
        });
    }, [dataProvider, id, locale, localizationData])

    const fileLocalizationCreate = () => {
        const dailyName = document.getElementById(`fileName_${locale}`);
        const dailyFile = document.querySelector(`.file_${locale}`);
        const dailyFileDuration = document.querySelector(`.fileDuration_${locale}`);
        const dailyFileType = document.getElementById(`fileType_${locale}`);
        const dailyDescription = document.getElementById(`fileDescription_${locale}`);
        const dailyImage = document.getElementById(`image_${locale}`);

        const dailyRequiredFields = document.querySelectorAll('.daily_required div.file-validation-wrap');
        const dailyRequiredError = document.querySelectorAll('.daily_required p');

        const dailyRequiredName = document.querySelector('.form-field.name.daily');
        const dailyRequiredDescription = document.querySelector('.form-field.description.daily');

        const dailyRequiredImageFields = document.querySelectorAll('.daily_image_required div');
        const dailyRequiredImageError = document.querySelectorAll('.daily_image_required p');

        if (dailyFileType.nextElementSibling.value > -1 && (!dailyFile || !dailyFile.value)) {
            dailyRequiredFields.forEach((item) => {
                item.classList.add('error-field');
            });

            dailyRequiredError.forEach((item) => {
                item.classList.add('errorText');
                item.removeAttribute('style')
            });

            notify('Success Story Error');
            return;
        }

        if (
            !dailyName.value || !dailyDescription.value || !dailyImage.value
        ) {
            TouchInput([dailyName, dailyDescription]);

            if (!dailyName.value) {
                dailyRequiredName.classList.add('error');
            }

            if (!dailyDescription.value) {
                dailyRequiredDescription.classList.add('error');
            }

            if (!dailyImage.value.length) {
                dailyRequiredImageFields.forEach((item) => {
                    item.classList.add('error-field');
                })
                dailyRequiredImageError.forEach((item) => {
                    item.classList.add('errorText');
                    item.removeAttribute('style')
                })
            }

            notify('Success Story Error');
            return;
        }

        const formData = {
            'name': dailyName.value,
            'file': dailyFile && dailyFile.nextElementSibling.files[0] ? dailyFile.nextElementSibling.files[0] : '',
            'file_duration': dailyFileDuration ? dailyFileDuration.value : 0,
            'file_type': dailyFileType ? dailyFileType.nextElementSibling.value : -1,
            'description': dailyDescription.value,
            'image': dailyImage.nextElementSibling.files[0] ? dailyImage.nextElementSibling.files[0] : dailyImage.value,
        };


        dispatch(setLoadStatus(true));
        if (!localizationData[locale].id) {
            create(`success-stories/${id}/${locale}`, {
                data: {
                    name: formData.name,
                    file: formData.file,
                    file_duration: formData.file_duration,
                    file_type: fileType,
                    description: formData.description,
                    image: formData.image
                }
            }, {
                onSuccess: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(`Success story localization ${locale} created`);
                    redirect(`/success-stories/general/${id}`);
                },
                onError: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(res.message, 'warning');
                }
            });
        }

        if (localizationData[locale].id) {
            dataProvider.update(`success-stories`, {
                id: `${id}/${locale}`, data: {
                    name: formData.name,
                    file: formData.file,
                    file_duration: formData.file_duration,
                    file_type: fileType,
                    description: formData.description,
                    image: formData.image
                }
            }).then(() => {
                dispatch(setLoadStatus(false));
                notify(`Success story localization ${locale} update`);
            }).catch((res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            });
        }
    }

    const Save = ({...props}) => {
        return (
            <div className="dailyActionToolbar">
                {props.localizationId &&
                    <LocalizationActions id={id} status={props.localizationStatus} url={'success-stories'}
                                         redirectUrl={'/success-stories/general'} prefix={locale}/>
                }
                <Link to="/success-stories/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={fileLocalizationCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const getFile = (e, durationElement) => {
        let file = e.currentTarget.files[0];
        if (!file || fileType === -1) {
            return;
        }

        const mimeType = file.type;

        const regex = fileType === 1 ? /audio\/mpeg/ : /video\/mp4/;
        const acceptTypes = fileType === 1 ? '.mp3' : '.mp4';
        if (!mimeType.match(regex)) {
            notify(`File should be in (${acceptTypes}) format.`, 'warning');
            file = null;
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContext.decodeAudioData(event.target.result, (buffer) => {
                const hours = Math.floor(buffer.duration / 3600);
                const minutes = Math.floor((buffer.duration % 3600) / 60);
                const seconds = Math.floor(buffer.duration % 60);

                const fileRequiredFields = document.querySelectorAll('.daily_required div');
                const fileRequiredError = document.querySelectorAll('.daily_required p');

                const duration = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

                fileRequiredFields.forEach((item) => {
                    item.className = '';
                });

                fileRequiredError.forEach((item) => {
                    item.className = '';
                    item.style.display = 'none';
                });

                setFile(file);
                setFileDuration(duration);
                setFileDurationValue(Math.floor(buffer.duration * 1000));
            });


        };
        reader.onerror = (event) => {
            console.error("An error occurred reading the file: ", event);
        };
        reader.readAsArrayBuffer(file);
    }

    const getFileType = (e) => {
        if (!e) {
            setFileType(e.target.value);
            localizationData[locale].file_type = -1;
        }

        setFile('');
        localizationData[locale].file_duration = 0;
        localizationData[locale].file_original_name = '';
        localizationData[locale].file_url = '';
        setFileDuration(0);
        setFileDurationValue(null);
        setFileType(e.target.value);
        localizationData[locale].file_type = e.target.value;
    }

    const uploadImage = (e, imageElement) => {
        const file = e.currentTarget.files[0];
        if (!file) {
            return;
        }

        const mimeType = file.type;
        const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!mimetypes.includes(mimeType)) {
            notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
            return;
        }

        const fileRequiredImageFields = document.querySelectorAll('.daily_image_required div');
        const fileRequiredImageError = document.querySelectorAll('.daily_image_required p');

        const reader = new FileReader();

        reader.onload = () => {
            fileRequiredImageFields.forEach((item) => {
                item.className = '';
            });

            fileRequiredImageError.forEach((item) => {
                item.className = '';
                item.style.display = 'none';
            });

            setImageSrc(reader.result);
        }

        reader.onerror = (event) => {
            console.error("An error occurred reading the file: ", event);
        };
        reader.readAsDataURL(file);
    }

    const onInputChange = (className) => {
        if (document.querySelector(className).classList.contains('error')) {
            document.querySelector(className).classList.remove('error');
        }
    }

    return (
        <SimpleForm record={localizationData[locale]} toolbar={<></>}>
            <div className="files-localization-form">
                <TextInput
                    label="Name"
                    className="form-field name daily"
                    name="name"
                    source="name"
                    id={`fileName_${locale}`}
                    defaultValue={localizationData[locale].name}
                    onChange={(e) => {
                        localizationData[locale].name = e.target.value;
                        onInputChange('.form-field.name.daily')
                    }}
                    validate={required()}
                    multiline
                    fullWidth
                />
                <p className="required-text">Required</p>

                <SelectInput
                    label="File Type"
                    name="file_type"
                    source="file_type"
                    id={`fileType_${locale}`}
                    defaultValue={localizationData[locale].file_type}
                    choices={[
                        {id: -1, name: 'None'},
                        {id: 1, name: 'Audio'},
                        {id: 2, name: 'Video'},
                    ]}
                    onChange={(e) => getFileType(e)}
                    validate={required()}
                    fullWidth/>

                {fileType === 1 &&
                    <>
                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File</span>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor={`audioFile_${locale}`} className="fileName">
                                            {!file && localizationData[locale].file_url &&
                                                <span>{localizationData[locale].file_original_name}</span>
                                            }
                                            {file && <span>{file.name}</span>}
                                        </label>
                                        <input
                                            type="hidden"
                                            className={`file_${locale}`}
                                            name={`file.en`}
                                            value={file ? file : localizationData[locale].file_url}
                                        />
                                        <input
                                            type="file"
                                            className="fileInput"
                                            id={`audioFile_${locale}`}
                                            accept=".mp3,audio/*"
                                            onChange={(e) => getFile(e, 'audioDuration')}
                                        />
                                        <label htmlFor={`audioFile_${locale}`} className="uploadButton">
                                            {file || localizationData[locale].file_url ? 'Update a File' : 'Upload a File'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>

                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File Duration</span>
                                    <label htmlFor={`audioDuration_${locale}`}></label>
                                    <FunctionField label="" source="file_duration" render={() => {
                                        let duration;
                                        if (localizationData[locale].file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].file_duration);

                                        return (
                                            <input
                                                id={`audioDuration_${locale}`}
                                                className="durationInput"
                                                name={`file_duration.en`}
                                                value={fileDuration ? fileDuration : duration}
                                                placeholder={'00:00:00'}
                                                readOnly={true}
                                            />
                                        );
                                    }}/>
                                    <input type="hidden" className={`fileDuration_${locale}`}
                                           value={fileDurationValue ? fileDurationValue : localizationData[locale].file_duration}/>

                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>
                    </>
                }

                {fileType === 2 &&
                    <>
                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File</span>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor={`videoFile_${locale}`} className="fileName">
                                            {!file && localizationData[locale].file_url &&
                                                <span>{localizationData[locale].file_original_name}</span>
                                            }
                                            {file &&
                                                <span>{file.name}</span>
                                            }
                                        </label>
                                        <input
                                            type="hidden"
                                            className={`file_${locale}`}
                                            name={`file.en`}
                                            value={file ? file : localizationData[locale].file_url}
                                        />
                                        <input
                                            type="file"
                                            className="fileInput"
                                            id={`videoFile_${locale}`}
                                            accept="video/mp4,video/x-m4v,video/*"
                                            onChange={(e) => getFile(e, 'videoDuration')}
                                        />
                                        <label htmlFor={`videoFile_${locale}`} className="uploadButton">
                                            {file || localizationData[locale].file_url ? 'Update a File' : 'Upload a File'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>

                        <div className="fileField form-field daily_required">
                            <div className="file-validation-wrap">
                                <div className="file-field-wrap">
                                    <span className="fieldTitle">File Duration</span>
                                    <label htmlFor={`videoDuration_${locale}`}></label>
                                    <FunctionField label="" source="file_duration" render={() => {
                                        let duration;
                                        if (localizationData[locale].file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].file_duration);

                                        return (
                                            <input
                                                id={`audioDuration_${locale}`}
                                                className="durationInput"
                                                name={`file_duration.en`}
                                                value={fileDuration ? fileDuration : duration}
                                                placeholder={'00:00:00'}
                                                readOnly={true}
                                            />
                                        );
                                    }}/>
                                    <input type="hidden" className={`fileDuration_${locale}`}
                                           value={fileDurationValue ? fileDurationValue : localizationData[locale].file_duration}/>
                                </div>
                            </div>
                            <p style={{display: 'none'}}>Required</p>
                        </div>
                    </>
                }

                <TextInput
                    label="Description"
                    className="form-field description daily"
                    name="description"
                    source="description"
                    id={`fileDescription_${locale}`}
                    defaultValue={localizationData[locale].description}
                    onChange={(e) => {
                        localizationData[locale].description = e.target.value;
                        onInputChange('.form-field.description.daily')
                    }}
                    style={{marginBottom: '20px'}}
                    multiline
                    fullWidth
                    validate={required()}
                />
                <p className="required-text">Required</p>

                <div className="form-field daily_image_required">
                    <span className="fieldTitle">Image</span>
                    <div>
                        <div className="imageWrap"
                             style={{height: imageSrc || localizationData[locale].image_url ? '194px' : 'fit-content'}}>
                            <img
                                className="image"
                                id={`image_preview_${locale}`}
                                src={imageSrc ? imageSrc : localizationData[locale].image_url}
                                alt=""
                            />
                            <p style={{display: 'none'}}>Required</p>
                        </div>
                        <label className="imageLabel" htmlFor={`imageFile_${locale}`}></label>
                        <input
                            type="hidden"
                            id={`image_${locale}`}
                            name="image.en"
                            value={imageSrc ? imageSrc : localizationData[locale].image_url}
                        />
                        <input
                            type="file"
                            id={`imageFile_${locale}`}
                            className="fileInput"
                            name="image.en"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => uploadImage(e)}
                        />
                        <label htmlFor={`imageFile_${locale}`} className="uploadButton">
                            {imageSrc || localizationData[locale].image_url ? 'Update a File' : 'Upload a File'}
                        </label>
                    </div>
                </div>
            </div>
            <Save localizationId={localizationData[locale].id} localizationStatus={localizationData[locale].status}/>
        </SimpleForm>
    );
}

export default SuccessStoryLocalization;