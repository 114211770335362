import React, {useState} from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    FunctionField, useRedirect
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import Comments from "./components/comments";
import UserDetails from "./components/userDetails";
import ReceiptValidationLogs from "./components/ReceiptValidationLogs";
import Purchases from "./components/Purchases";
import GradientButton from "../../Component/Layouts/Button/GradientButton";
import PurpleBorderButton from "../../Component/Layouts/Button/PurpleBorderButton";
import {ChangePasswordForm} from "./edit/change-password";

const useStyles = makeStyles({
    userDetails: {
        padding: '16px 0 0',
        margin: '0 0 56px'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        margin: '0 0 12px',

        fontFamily: `'Roboto', sans-serif`,
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: '#4A1D52'
    },
    userDetailsRow: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px 0 8px',
        borderBottom: '1px solid #E4E4E6'
    },
    userDetailsTitle: {
        minWidth: '192px',
        margin: '0 16px 0 0',

        fontFamily: `'Roboto', sans-serif`,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: '#7F8DA0'
    },
    userDetailsDescription: {
        margin: '0',
        fontFamily: `'Roboto', sans-serif`,
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '20px',
        letterSpacing: '0.25px',
        color: '#323238'
    },
    actionBtn: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '64px',
        padding: '4px 5px',
        fontSize: '0.8125rem',
        border: 'none',
        outline: 'none',
        background: 'unset',
        cursor: 'pointer',
        borderRadius: '4px'
    },
    addCommentBtnWrap: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '0 0 24px'
    },
    listWrap: {
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(79, 71, 120, 0.16)',
        borderRadius: '8px',
        margin: '0 0 56px',

        '& .MuiTablePagination-root .MuiToolbar-root': {
            background: '#fff',
        },

        '& table tr td:last-child': {
            '& button': {
                minWidth: 'unset'
            }
        }
    }
});

export const CustomerShow = () => {
    const classes = useStyles();
    const redirect = useRedirect();
    const [showModal, setShowModal] = useState(false)
    const [customerId, setCustomerId] = useState(null)

    const closeChangePasswordModal = () => {
        setShowModal(false);
        setCustomerId(null)
    }

    const openPasswordChangeModal = (userId) => {
        setShowModal(true)
        setCustomerId(userId)
    }

    const navigateToEdit = (userId) => {
        redirect(`/customers/${userId}`)
    }

    return (
        <>
            <Show actions={<></>}>
                <TabbedShowLayout>
                    <Tab label="Details">
                        <FunctionField label="" source="userDetails" render={(record) => {
                            return <>
                                <div className={'edit-btns-user-wrap'}>
                                    {record.can_change_password ? <PurpleBorderButton label={'Change Password'} onClick={() => openPasswordChangeModal(record.id)}/> : null }
                                    <GradientButton label={'Edit'} onClick={() => navigateToEdit(record.id)} />
                                </div>
                                <UserDetails source='data' record={record} classes={classes}/>
                            </>
                        }}/>
                        <FunctionField label="" source="userDetails" render={(record) => {
                            return <Comments customerId={record.id} source='data' classes={classes}/>
                        }}/>
                        <ReceiptValidationLogs classes={classes}/>
                    </Tab>
                    <Tab label="Purchases" path="purchases">
                        <Purchases classes={classes}/>
                    </Tab>
                </TabbedShowLayout>
            </Show>
            {showModal && (<ChangePasswordForm customerId={customerId} onClose={() => closeChangePasswordModal()}></ChangePasswordForm>)}
        </>
    );
};
