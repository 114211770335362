import {
    getMutationMode,
    useDeleteWithConfirmController,
    useResourceContext,
    useTranslate
} from "ra-core";
import React, {Fragment} from "react";
import classnames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import PropTypes from "prop-types";
import ConfirmModal from "./Confirm";
import {Button} from "@mui/material";
import inflection from 'inflection';

export const DeleteWithConfirmationModalButton = (props) => {
    const {
        classes: classesOverride,
        className,
        confirmTitle = 'ra.message.delete_title',
        confirmContent = 'ra.message.delete_content',
        icon = defaultIcon,
        label = 'ra.action.delete',
        mutationMode,
        onClick,
        record,
        redirect = 'list',
        onSuccess,
        onFailure,
        undoable,
        id,
        ...rest
    } = props;
    const translate = useTranslate();
    const classes = useStyles(props);
    const resource = useResourceContext(props);
    const mode = getMutationMode(mutationMode, undoable);

    const {
        open,
        loading,
        handleDialogOpen,
        handleDialogClose,
        handleDelete,
    } = useDeleteWithConfirmController({
        record,
        redirect,
        mutationMode: mutationMode || mode,
        onClick,
        onSuccess,
        onFailure,
        resource,
    });

    return (
        <Fragment>
            <Button
                onClick={handleDialogOpen}
                label={label}
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                key="button"
                {...rest}
            >
                {icon}
            </Button>
            <ConfirmModal
                isOpen={open}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                translateOptions={{
                    name: translate(`resources.${resource}.forcedCaseName`, {
                        smart_count: 1,
                        _: inflection.humanize(
                            translate(`resources.${resource}.name`, {
                                smart_count: 1,
                                _: inflection.singularize(resource),
                            }),
                            true
                        ),
                    }),
                    id
                }}
                record={record}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
                type={'delete'}
            />
        </Fragment>
    );
};

const defaultIcon = <svg fill={'#f44336'} className={`MuiSvgIcon-root delete-icon`} focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="1em" height="1em">
    <path fill={'#f44336'} d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
</svg>;

const useStyles = makeStyles(
    theme => ({
        deleteButton: {
            color: '#f44336',

            '& .delete-icon': {
                fontSize: '20px'
            },

            '&:hover': {
                backgroundColor: alpha(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaDeleteWithConfirmButton' }
);

DeleteWithConfirmationModalButton.propTypes = {
    classes: PropTypes.object,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    mutationMode: PropTypes.oneOf(['pessimistic', 'optimistic', 'undoable']),
    undoable: PropTypes.bool,
    record: PropTypes.any,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    icon: PropTypes.element,
    id: PropTypes.any
};