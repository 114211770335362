import {
    Edit,
    required,
    TextInput,
    BooleanInput,
    Link,
    SimpleForm,
    TabbedShowLayout,
    Tab,
    useNotify,
    useDataProvider,
    FunctionField,
    useRefresh, NumberInput, minValue
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useParams} from "react-router-dom";
import WebinarHistoryLocalization from "./webinarHistoryLocalization";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";
import {Calendar} from "react-multi-date-picker";
import {convertedDate, convertedDateWithTime} from "../../../Functions/ConvertedDate";

export const EditWebinarHistory = () => {
    const {id} = useParams();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const onDateChange = (e) => {
        if (e && e.value.unix) {
            const date = new Date(e.value.unix * 1000);
            formData.show_date = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        }
    }

    const initialRef = useRef(true);
    const [formData] = useState({
        name: '',
        description: '',
        show_date: '',
        order: 0,
        published: false,
        subscription: false
    });

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;
    }, []);

    const editCreate = () => {
        if (!formData.name.length || formData.order <= 0) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }

            if (formData.order <= 0) {
                document.querySelector('.order').classList.add('error');
            }

            return;
        }

        dispatch(setLoadStatus(true));

        dataProvider.update(`webinar-histories/general`, {id: `${id}`, data: {
                name: formData.name,
                show_date: formData.show_date || document.getElementById('show_date').value,
                description: formData.description,
                order: formData.order,
                published: formData.published ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False
            }}).then(() => {
            dispatch(setLoadStatus(false));
            notify(`Webinar update`);
            refresh(true);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                    <Link to="/webinar-histories/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    const orderChange = (e) => {
        const val = +e.target.value;
        if (val <= 0) {
            document.querySelector('.order').classList.add('error');
            return;
        }
        if (document.querySelector('.order').classList.contains('error')) {
            document.querySelector('.order').classList.remove('error');
        }
        formData.order = val;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit Webinar</h2>
            <Edit title="Webinar">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <div className="tabbedFormWrap">
                                <FunctionField label="" render={(record) => {
                                    formData.name = record.name;
                                    return <>
                                        <TextInput className="formFields name" label="Name" name="name"
                                                   source="formData.name" fullWidth id="name" validate={required()}
                                                   onChange={(e) => {
                                                       nameChange(e)
                                                   }} multiline/>
                                        <p className="required-text">Required</p>
                                    </>;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.description = record.description;
                                    return <TextInput className="formFields" label="Description" name="description" source="formData.description" id="description" multiline fullWidth onChange={(e) => {
                                        formData.description = e.target.value
                                    }} />;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.order = record.order;
                                    return <>
                                        <NumberInput label="Order" name="order" source="order" min={1} className="numberInput order" fullWidth id="order" validate={[required(), minValue(1)]} onChange={(e) => {orderChange(e)}}/>
                                        <p className="required-text">Must be at least 1</p>
                                    </>;
                                }}/>
                                <span className="showDateLabel">Show Date</span>
                                <FunctionField source="show_date" render={(record) => {
                                    return (
                                        <>
                                            <Calendar
                                                value={record.show_date ? convertedDateWithTime(record.show_date) : null}
                                                onPropsChange={(e) => {
                                                    onDateChange(e)
                                                }}
                                                minDate="2000/01/01"
                                                maxDate="3000/12/31"
                                            />
                                            <input type="hidden" id="show_date"
                                                   value={record.show_date && !formData.show_date ? convertedDate(record.show_date) : formData.show_date}/>
                                        </>
                                    );
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.published = record.published;
                                    return <BooleanInput label="Visible"
                                                         name="published"
                                                         source="published"
                                                         className="booleanInput"
                                                         format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                         parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                         onChange={(e) => {
                                                             formData.published = e.target.checked
                                                         }}/>;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.subscription = record.subscription;
                                    return <BooleanInput label="Subscription Required"
                                                         name="subscription" source="subscription"
                                                         className="booleanInput"
                                                         format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                         parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                         onChange={(e) => {
                                                             formData.subscription = e.target.checked
                                                         }}/>;
                                }}/>
                            </div>
                            <Save />
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <WebinarHistoryLocalization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <WebinarHistoryLocalization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <WebinarHistoryLocalization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}