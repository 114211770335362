import {
    TextInput,
    SimpleForm,
    useCreate,
    useNotify,
    Link,
    useRedirect,
    useDataProvider, required, FunctionField
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {LocalizationActions} from "../../../Component/Layouts/LocalizationActions";
import {useParams} from "react-router-dom";
import setLoadStatus from "../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";

const LOCALIZATION_DATA = {
    id: '',
    name: '',
    link: '',
    image_url: '',
}

const WebinarLocalization = () => {
    const { '*': locale, id } = useParams();
    const initialRef = useRef(true);
    const [localizationData, setLocalizationData] = useState({[locale]: LOCALIZATION_DATA});
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const formData = {
        name: '',
        link: '',
        image: ''
    };

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        dataProvider.getOne('webinars', {id: `${id}/${locale}`}).then((res) => {
            setLocalizationData({[locale]: LOCALIZATION_DATA});
            if (res.data.id !== 0) {
                setLocalizationData({[locale]: res.data});
            }
        });
    }, [dataProvider, id, locale])

    const webinarLocalizationCreate = () => {
        if (!formData.link.length || !formData.image || !formData.name) {
            if (!formData.link.length) {
                document.querySelector('.member_link').classList.add('error');
            }

            if (!formData.image.length) {
                document.querySelector('.image-required').classList.add('error');
            }

            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }

            return;
        }
        dispatch(setLoadStatus(true));
        if (!localizationData[locale].id) {
            create(`webinars/${id}/${locale}`, {data: {
                    name: formData.name,
                    link: formData.link,
                    image: formData.image
                }}, {
                onSuccess: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(`Webinar localization ${locale} created`);
                    redirect(`/webinars/general/${id}`);
                },
                onError: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(res.message, 'warning');
                }
            });
        }

        if (localizationData[locale].id) {
            dataProvider.update(`webinars`, {id: `${id}/${locale}`, data: {
                    name: formData.name,
                    link: formData.link,
                    image: formData.image
                }}).then(() => {
                dispatch(setLoadStatus(false));
                notify(`Webinar localization ${locale} update`);
                redirect(`/webinars/general/${id}`);
            }).catch((res) => {
                dispatch(setLoadStatus(false));
                notify(res.message, 'warning');
            });
        }
    }

    const Save = ({...props}) => {
        return (
            <div className="dailyActionToolbar">
                {props.localizationId &&
                    <LocalizationActions id={id} status={props.localizationStatus} url={'webinars'} redirectUrl={'/webinars/general'} prefix={locale}/>
                }
                <Link to="/webinars/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={webinarLocalizationCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const ImageUploadField = () => {
        const [imageSrc, setImageSrc] = useState(null);

        const uploadImage = async (imageFile) => {
            if (!imageFile) {
                return;
            }

            const mimeType = imageFile.type;
            const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!mimetypes.includes(mimeType)) {
                notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
                return;
            }

            if (document.querySelector('.image-required').classList.contains('error')) {
                document.querySelector('.image-required').classList.remove('error');
            }

            formData.image = imageFile;

            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result);
            }

            reader.readAsDataURL(imageFile);
        }

        return <div className="form-field image-required">
            <span className="fieldTitle">Image</span>
            <div>
                <div className="imageWrap">
                        <img
                            className="image"
                            src={imageSrc ? imageSrc : formData.image}
                            alt=""
                        />
                    <p className="required-text">Required</p>
                </div>
                <label className="imageLabel" htmlFor="imageInput"></label>
                <input
                    type="file"
                    id="imageInput"
                    className="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadImage(e.currentTarget.files[0])}
                />
                <label htmlFor="imageInput" className="uploadButton">
                    {formData.image ? 'Update Image' : 'Choose an Image'}
                </label>
            </div>
        </div>;
    }

    const linkChange = (e) => {
        if (document.querySelector('.member_link').classList.contains('error')) {
            document.querySelector('.member_link').classList.remove('error');
        }

        formData.link = e.target.value;
    }

    const titleChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }

        formData.name = e.target.value;
    }

    return (
        <SimpleForm record={localizationData[locale]} toolbar={<></>}>
                <FunctionField label="" render={(record) => {
                    formData.name = record.name;
                    return <>
                        <TextInput className="formFields name" label="Title" name="name"
                                   source="formData.name" fullWidth id="name" validate={required()}
                                   onChange={(e) => {
                                       titleChange(e)
                                   }} multiline/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <FunctionField label="" render={(record) => {
                    formData.link = record.link;
                    return <>
                        <TextInput className="formFields member_link" label="Link" name="link"
                               source="formData.link" fullWidth id="link" validate={required()}
                               onChange={(e) => {
                                   linkChange(e)
                               }} multiline/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <p className="uploadImageLabel"></p>
                <FunctionField label="" render={(record) => {
                    formData.image = record.image_url;
                    return <ImageUploadField />;
                }}/>
                <Save localizationId={localizationData[locale].id} localizationStatus={localizationData[locale].status} />
        </SimpleForm>
    );
}

export default WebinarLocalization;