import {Link, useDataProvider, useNotify, useRedirect} from "react-admin";
import * as React from "react";
import {TouchInput} from "../../../../Functions/TouchInput";
import setLoadStatus from "../../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";

export const DailyDareGeneralActions = (data) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const id = data.id;
    const dispatch = useDispatch();

    const onSubmit = () => {
        const name = document.getElementById('name');
        const description = document.getElementById('description');
        const show_date = document.getElementById('show_date');

        if (!name.value) {
            TouchInput([name]);
            notify('Daily Dare General Error: Name is required');
            return;
        }

        const formData = {
            name: name.value,
            description: description.value,
            show_date: show_date.value
        };

        if (id) {
            dispatch(setLoadStatus(true));
            dataProvider.update('daily-dares/general', {id: id, data: formData}).then(() => {
                notify('Daily Dare General updated');
                redirect('/daily-dares/general');
                dispatch(setLoadStatus(false));
            }).catch((err) => {
                notify(err.message, {type: 'error'});
                dispatch(setLoadStatus(false));
            });
        } else {
            dispatch(setLoadStatus(true));
            dataProvider.create('daily-dares/general', {data: formData}).then((res) => {
                notify('Daily Dare General created');
                redirect(`/daily-dares/general/${res.data.id}/en`);
                dispatch(setLoadStatus(false));
            }).catch((err) => {
                notify(err.message, {type: 'error'});
                dispatch(setLoadStatus(false));
            });
        }
    };

    return (
        <div className="dailyActionToolbar">
            <>
                    <Link to="/daily-dares/general" className="button bar-purple-button">Cancel</Link>
                    <button className="button bar-gradient-button" type="button" onClick={onSubmit}>
                        Save
                    </button>
            </>
        </div>
    );
};