import {FunctionField, required, SelectInput, SimpleForm, TextInput, useDataProvider, useNotify} from "react-admin";
import * as React from "react";
import {getFileDurationFromTimestamp} from "../../../../Functions/ConvertFileDuration";
import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {LocalizationActions} from "../../../../Component/Layouts/LocalizationActions";
import {DailyLocalizationActions} from "./dailyLocalizationActions";
import {handleDownload} from "../../../../Functions/DownloadFile";

const LOCALIZATION_DATA = {
    id: '',
    status: 1,
    daily_dare: {
        description: '',
        file_duration: '',
        file_original_name: '',
        file_type: 1,
        file_url: '',
        id: '',
        image_url: '',
        name: ''
    },
    wind_down: {
        description: '',
        file_duration: '',
        file_original_name: '',
        file_type: 1,
        file_url: '',
        id: '',
        image_url: '',
        name: ''
    }
}

export const Localization = () => {
    const { '*': locale, id } = useParams();
    const initialRef = useRef(true);

    const [localizationData, setLocalizationData] = useState({[locale]: LOCALIZATION_DATA });
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const [dailyDareFileDuration, setDailyDareFileDuration] = useState('');
    const [dailyDareFileDurationValue, setDailyDareFileDurationValue] = useState(null);
    const [windDownFileDuration, setWindDownFileDuration] = useState('');
    const [windDownFileDurationValue, setWindDownFileDurationValue] = useState(null);
    const [dailyDareFile, setDailyDareFile] = useState('');
    const [windDownFile, setWindDownFile] = useState('');
    const [dailyDareImageSrc, setDailyDareImageSrc] = useState(null);
    const [windDownImageSrc, setWindDownImageSrc] = useState(null);
    const [fileDailyType, setFileDailyType] = useState(1);
    const [fileWindType, setFileWindType] = useState(1);

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        localizationData[locale].daily_dare.name = '';
        localizationData[locale].daily_dare.description = '';

        localizationData[locale].wind_down.name = '';
        localizationData[locale].wind_down.description = '';

        localizationData[locale].daily_dare.file_type = 1;
        localizationData[locale].wind_down.file_type = 1;

        dataProvider.getOne('daily-dares', {id: `${id}/${locale}`}).then((res) => {
            setLocalizationData({[locale]: LOCALIZATION_DATA});
            if (res.data.id !== 0) {
                setLocalizationData({[locale]: res.data});
                setFileDailyType(res.data.daily_dare.file_type);
                setFileWindType(res.data.wind_down.file_type);

                if (!res.data.daily_dare?.length) {
                    setFileDailyType(1);
                }

                if (!res.data.wind_down?.length) {
                    setFileWindType(1);
                }
            }
        });
    }, [dataProvider, id, locale, localizationData])

    const getFile = (e, durationElement, fileType) => {
        let file = e.currentTarget.files[0];
        if(!file) {
            return;
        }

        const mimeType = file.type;

        if (fileType === 'dailyDareFile') {
            const regex = fileDailyType === 1 ? /audio\/mpeg/ : /video\/mp4/;
            const acceptTypes = fileDailyType === 1 ? '.mp3' : '.mp4';
            if (!mimeType.match(regex)) {
                notify(`File should be in (${acceptTypes}) format.`, 'warning');
                file = null;
                return;
            }
        }

        if (fileType === 'windDownFile') {
            const regex = fileWindType === 1 ? /audio\/mpeg/ : /video\/mp4/;
            const acceptTypes = fileWindType === 1 ? '.mp3' : '.mp4';
            if (!mimeType.match(regex)) {
                notify(`File should be in (${acceptTypes}) format.`, 'warning');
                file = null;
                return;
            }
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            audioContext.decodeAudioData(event.target.result, (buffer) => {
                const hours = Math.floor(buffer.duration / 3600);
                const minutes = Math.floor((buffer.duration % 3600) / 60);
                const seconds = Math.floor(buffer.duration % 60);

                const dailyRequiredFields = document.querySelectorAll('.daily_required div.file-validation-wrap');
                const dailyRequiredError = document.querySelectorAll('.daily_required p');
                const windRequiredFields = document.querySelectorAll('.wind_required div.file-validation-wrap');
                const windRequiredError = document.querySelectorAll('.wind_required p');

                const duration = ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2) + ':' + ('0' + seconds).slice(-2);

                if (fileType === 'dailyDareFile') {
                    dailyRequiredFields.forEach((item) => {
                        item.className = '';
                    });

                    dailyRequiredError.forEach((item) => {
                        item.className = '';
                        item.style.display = 'none';
                    });

                    setDailyDareFile(file);
                    setDailyDareFileDuration(duration);
                    setDailyDareFileDurationValue(Math.floor(buffer.duration * 1000));
                }

                if (fileType === 'windDownFile') {
                    windRequiredFields.forEach((item) => {
                        item.className = '';
                    });

                    windRequiredError.forEach((item) => {
                        item.className = '';
                        item.style.display = 'none';
                    });

                    setWindDownFile(file);
                    setWindDownFileDuration(duration);
                    setWindDownFileDurationValue(Math.floor(buffer.duration * 1000));
                }
            });


        };
        reader.onerror = (event) => {
            console.error("An error occurred reading the file: ", event);
        };
        reader.readAsArrayBuffer(file);
    }

    const getFileType = (e, fileType) => {
        if (!e) {
            setFileDailyType(e.target.value);
            localizationData[locale].daily_dare.file_type = 1;
            setFileWindType(e.target.value);
            localizationData[locale].wind_down.file_type = 1;
        }

        if (fileType === 'dailyDareFile') {
            setDailyDareFile('');
            localizationData[locale].daily_dare.file_duration = '';
            localizationData[locale].daily_dare.file_original_name = '';
            localizationData[locale].daily_dare.file_url = '';
            setDailyDareFileDuration('');
            setDailyDareFileDurationValue(null);
            setFileDailyType(e.target.value);
            localizationData[locale].daily_dare.file_type = e.target.value;
        }

        if (fileType === 'windDownFile') {
            setWindDownFile('');
            localizationData[locale].wind_down.file_duration = '';
            localizationData[locale].wind_down.file_original_name = '';
            localizationData[locale].wind_down.file_url = '';
            setWindDownFileDuration('');
            setWindDownFileDurationValue(null);
            setFileWindType(e.target.value);
            localizationData[locale].wind_down.file_type = e.target.value;
        }
    }

    const uploadImage = (e, imageElement) => {
        const file = e.currentTarget.files[0];
        if(!file) {
            return;
        }

        const mimeType = file.type;
        const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

        if (!mimetypes.includes(mimeType)) {
            notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
            return;
        }

        const dailyRequiredImageFields = document.querySelectorAll('.daily_image_required div');
        const dailyRequiredImageError = document.querySelectorAll('.daily_image_required p');
        const windRequiredImageFields = document.querySelectorAll('.wind_image_required div');
        const windRequiredImageError = document.querySelectorAll('.wind_image_required p');

        const reader = new FileReader();

        reader.onload = () => {
            if (imageElement === 'dailyDare') {
                dailyRequiredImageFields.forEach((item) => {
                    item.className = '';
                });

                dailyRequiredImageError.forEach((item) => {
                    item.className = '';
                    item.style.display = 'none';
                });

                setDailyDareImageSrc(reader.result);
            }
            if (imageElement === 'windDown') {
                windRequiredImageFields.forEach((item) => {
                    item.className = '';
                });

                windRequiredImageError.forEach((item) => {
                    item.className = '';
                    item.style.display = 'none';
                });

                setWindDownImageSrc(reader.result);
            }
        }

        reader.onerror = (event) => {
            console.error("An error occurred reading the file: ", event);
        };
        reader.readAsDataURL(file);
    }

    const onInputChange = (className) => {
        if (document.querySelector(className).classList.contains('error')) {
            document.querySelector(className).classList.remove('error');
        }
    }

    return (
        <SimpleForm toolbar={<></>} record={localizationData[locale]}>
            <div className="localization-form">
                <div className="form-wrap">
                    <TextInput
                        label="Name"
                        className="form-field name daily"
                        name="daily_dare.name"
                        source="daily_dare.name"
                        id={`dailyName_${locale}`}
                        defaultValue={localizationData[locale].daily_dare.name}
                        onChange={(e) => {localizationData[locale].daily_dare.name = e.target.value; onInputChange('.form-field.name.daily')}}
                        validate={required()}
                        fullWidth
                        multiline
                    />
                    <p className="required-text">Required</p>

                    <SelectInput
                        label="File Type"
                        name="daily_dare.file_type"
                        source="daily_dare.file_type"
                        id={`dailyFileType_${locale}`}
                        defaultValue={localizationData[locale].daily_dare.file_type}
                        choices={[
                            { id: 1, name: 'Audio' },
                            { id: 2, name: 'Video' },
                        ]}
                        onChange={(e) => getFileType(e, 'dailyDareFile')}
                        validate={required()}
                        fullWidth/>

                    {fileDailyType === 1 &&
                        <>
                            <div className="fileField form-field daily_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                    <span className="fieldTitle">File</span>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <label htmlFor={`dailyAudioFile_${locale}`} className="fileName">
                                            {!dailyDareFile && localizationData[locale].daily_dare.file_url &&
                                                <span>{localizationData[locale].daily_dare.file_original_name}</span>
                                            }
                                            {dailyDareFile && <span>{dailyDareFile.name}</span>}
                                        </label>
                                        <input
                                            type="hidden"
                                            className={`dailyFile_${locale}`}
                                            name={`daily_dare_file.en`}
                                            value={dailyDareFile ? dailyDareFile : localizationData[locale].daily_dare.file_url}
                                        />
                                        <input
                                            type="file"
                                            className="fileInput"
                                            id={`dailyAudioFile_${locale}`}
                                            accept=".mp3,audio/*"
                                            onChange={(e) => getFile(e, 'dailyAudioDuration', 'dailyDareFile')}
                                        />
                                        <label htmlFor={`dailyAudioFile_${locale}`} className="uploadButton">
                                            {dailyDareFile || localizationData[locale].daily_dare.file_url ? 'Update a File' : 'Upload a File'}
                                        </label>
                                        {localizationData[locale].daily_dare.file_url && <button type="button" className="uploadButton download-btn" onClick={() => handleDownload(localizationData[locale].daily_dare.file_original_name, localizationData[locale].daily_dare.file_url)}>
                                            <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#FFF"/>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#FFF"/>
                                            </svg>
                                            Download file
                                        </button>}
                                    </div>
                                </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>

                            <div className="fileField form-field daily_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File Duration</span>
                                        <label htmlFor={`dailyAudioDuration_${locale}`}></label>
                                        <FunctionField label="" source="daily_dare.file_duration" render={() => {
                                            let duration;
                                            if (localizationData[locale].daily_dare.file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].daily_dare.file_duration);

                                            return (
                                                <input
                                                    id={`dailyAudioDuration_${locale}`}
                                                    className="durationInput"
                                                    name={`daily_dare[file_duration].en`}
                                                    value={dailyDareFileDuration ? dailyDareFileDuration : duration }
                                                    placeholder={'00:00:00'}
                                                    readOnly={true}
                                                />
                                            );
                                        }}/>
                                        <input type="hidden" className={`dailyFileDuration_${locale}`} value={dailyDareFileDurationValue ? dailyDareFileDurationValue : localizationData[locale].daily_dare.file_duration}/>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>
                        </>
                    }

                    {fileDailyType === 2 &&
                        <>
                            <div className="fileField form-field daily_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File</span>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <label htmlFor={`dailyVideoFile_${locale}`} className="fileName">
                                                {!dailyDareFile && localizationData[locale].daily_dare.file_url &&
                                                    <span>{localizationData[locale].daily_dare.file_original_name}</span>
                                                }
                                                {dailyDareFile &&
                                                    <span>{dailyDareFile.name}</span>
                                                }
                                            </label>
                                            <input
                                                type="hidden"
                                                className={`dailyFile_${locale}`}
                                                name={`daily_dare_file.en`}
                                                value={dailyDareFile ? dailyDareFile : localizationData[locale].daily_dare.file_url}
                                            />
                                            <input
                                                type="file"
                                                className="fileInput"
                                                id={`dailyVideoFile_${locale}`}
                                                accept="video/mp4,video/x-m4v,video/*"
                                                onChange={(e) => getFile(e, 'dailyVideoDuration', 'dailyDareFile')}
                                            />
                                            <label htmlFor={`dailyVideoFile_${locale}`} className="uploadButton">
                                                {dailyDareFile || localizationData[locale].daily_dare.file_url ? 'Update a File' : 'Upload a File'}
                                            </label>
                                            {localizationData[locale].daily_dare.file_url && <button type="button" className="uploadButton download-btn" onClick={() => handleDownload(localizationData[locale].daily_dare.file_original_name, localizationData[locale].daily_dare.file_url)}>
                                                <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#FFF"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#FFF"/>
                                                </svg>
                                                Download file
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>

                            <div className="fileField form-field daily_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File Duration</span>
                                        <label htmlFor={`dailyVideoDuration_${locale}`}></label>
                                        <FunctionField label="" source="daily_dare.file_duration" render={() => {
                                            let duration;
                                            if (localizationData[locale].daily_dare.file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].daily_dare.file_duration);

                                            return (
                                                <input
                                                    id={`dailyAudioDuration_${locale}`}
                                                    className="durationInput"
                                                    name={`daily_dare[file_duration].en`}
                                                    value={dailyDareFileDuration ? dailyDareFileDuration : duration }
                                                    placeholder={'00:00:00'}
                                                    readOnly={true}
                                                />
                                            );
                                        }}/>
                                        <input type="hidden" className={`dailyFileDuration_${locale}`} value={dailyDareFileDurationValue ? dailyDareFileDurationValue : localizationData[locale].daily_dare.file_duration}/>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>
                        </>
                    }

                    <TextInput
                        label="Description"
                        className="form-field description daily"
                        name="daily_dare.description"
                        source="daily_dare.description"
                        id={`dailyDescription_${locale}`}
                        defaultValue={localizationData[locale].daily_dare.description}
                        onChange={(e) => {localizationData[locale].daily_dare.description = e.target.value; onInputChange('.form-field.description.daily')}}
                        style={{marginBottom: '20px'}}
                        multiline
                        fullWidth
                        validate={required()}
                    />
                    <p className="required-text">Required</p>

                    <div className="form-field daily_image_required">
                        <span className="fieldTitle">Image</span>
                        <div>
                            <div className="imageWrap" style={{height: dailyDareImageSrc || localizationData[locale].daily_dare.image_url ? '194px' : 'fit-content'}}>
                                <img
                                    className="image"
                                    id={`image_preview_${locale}`}
                                    src={dailyDareImageSrc ? dailyDareImageSrc : localizationData[locale].daily_dare.image_url}
                                    alt=""
                                />
                                <p style={{display: 'none'}}>Required</p>
                            </div>
                            <label className="imageLabel" htmlFor={`dailyImageFile_${locale}`}></label>
                            <input
                                type="hidden"
                                id={`dailyImage_${locale}`}
                                name="daily_dare_image.en"
                                value={dailyDareImageSrc ? dailyDareImageSrc : localizationData[locale].daily_dare.image_url}
                            />
                            <input
                                type="file"
                                id={`dailyImageFile_${locale}`}
                                className="fileInput"
                                name="daily_dare_image.en"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={(e) => uploadImage(e, 'dailyDare')}
                            />
                            <label htmlFor={`dailyImageFile_${locale}`} className="uploadButton">
                                {dailyDareImageSrc || localizationData[locale].daily_dare.image_url ? 'Update a File' : 'Upload a File'}
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-wrap">
                    <TextInput
                        label="Name"
                        className="form-field name wind"
                        name="wind_down.name"
                        source="wind_down.name"
                        id={`windName_${locale}`}
                        defaultValue={localizationData[locale].wind_down.name}
                        onChange={(e) => {localizationData[locale].wind_down.name = e.target.value; onInputChange('.form-field.name.wind')}}
                        validate={required()}
                        fullWidth
                        multiline
                    />
                    <p className="required-text">Required</p>

                    <SelectInput
                        label="File Type"
                        name="wind_down.file_type"
                        source="wind_down.file_type"
                        id={`windFileType_${locale}`}
                        defaultValue={localizationData[locale].wind_down.file_type}
                        choices={[
                            { id: 1, name: 'Audio' },
                            { id: 2, name: 'Video' },
                        ]}
                        onChange={(e) => getFileType(e, 'windDownFile')}
                        validate={required()}
                        fullWidth/>

                    {fileWindType === 1 &&
                        <>
                            <div className="fileField form-field wind_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File</span>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <label htmlFor={`windAudioFile_${locale}`} className="fileName">
                                                {!windDownFile && localizationData[locale].wind_down.file_url &&
                                                    <span>{localizationData[locale].wind_down.file_original_name}</span>
                                                }
                                                {windDownFile && <span>{windDownFile.name}</span>}
                                            </label>
                                            <input
                                                type="hidden"
                                                className={`windFile_${locale}`}
                                                name={`daily_dare_file.en`}
                                                value={windDownFile ? windDownFile : localizationData[locale].wind_down.file_url}
                                            />
                                            <input
                                                type="file"
                                                className="fileInput"
                                                id={`windAudioFile_${locale}`}
                                                accept=".mp3,audio/*"
                                                onChange={(e) => getFile(e, 'windAudioDuration', 'windDownFile')}
                                            />
                                            <label htmlFor={`windAudioFile_${locale}`} className="uploadButton">
                                                {windDownFile || localizationData[locale].wind_down.file_url ? 'Update a File' : 'Upload a File'}
                                            </label>
                                            {localizationData[locale].wind_down.file_url && <button type="button" className="uploadButton download-btn" onClick={() => handleDownload(localizationData[locale].wind_down.file_original_name, localizationData[locale].wind_down.file_url)}>
                                                <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#FFF"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#FFF"/>
                                                </svg>
                                                Download file
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>

                            <div className="fileField form-field wind_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File Duration</span>
                                        <label htmlFor={`windAudioDuration_${locale}`}></label>
                                        <FunctionField label="" source="wind_down.file_duration" render={() => {
                                            let duration;
                                            if (localizationData[locale].wind_down.file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].wind_down.file_duration);

                                            return (
                                                <input
                                                    id={`windAudioDuration_${locale}`}
                                                    className="durationInput"
                                                    name={`wind_down[file_duration].en`}
                                                    value={windDownFileDuration ? windDownFileDuration : duration }
                                                    placeholder={'00:00:00'}
                                                    readOnly={true}
                                                />
                                            );
                                        }}/>
                                        <input type="hidden" className={`windFileDuration_${locale}`} value={windDownFileDurationValue ? windDownFileDurationValue : localizationData[locale].wind_down.file_duration}/>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>
                        </>
                    }

                    {fileWindType === 2 &&
                        <>
                            <div className="fileField form-field wind_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File</span>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <label htmlFor={`windVideoFile_${locale}`} className="fileName">
                                                {!windDownFile && localizationData[locale].wind_down.file_url &&
                                                    <span>{localizationData[locale].wind_down.file_original_name}</span>
                                                }
                                                {windDownFile &&
                                                    <span>{windDownFile.name}</span>
                                                }
                                            </label>
                                            <input
                                                type="hidden"
                                                className={`windFile_${locale}`}
                                                name={`wind_down_file.en`}
                                                value={windDownFile ? windDownFile : localizationData[locale].wind_down.file_url}
                                            />
                                            <input
                                                type="file"
                                                className="fileInput"
                                                id={`windVideoFile_${locale}`}
                                                accept="video/mp4,video/x-m4v,video/*"
                                                onChange={(e) => getFile(e, 'windVideoDuration', 'windDownFile')}
                                            />
                                            <label htmlFor={`windVideoFile_${locale}`} className="uploadButton">
                                                {windDownFile || localizationData[locale].wind_down.file_url ? 'Update a File' : 'Upload a File'}
                                            </label>
                                            {localizationData[locale].wind_down.file_url && <button type="button" className="uploadButton download-btn" onClick={() => handleDownload(localizationData[locale].wind_down.file_original_name, localizationData[locale].wind_down.file_url)}>
                                                <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 22C23 22.5523 22.5523 23 22 23H2C1.44772 23 1 22.5523 1 22C1 21.4477 1.44772 21 2 21H22C22.5523 21 23 21.4477 23 22Z" fill="#FFF"/>
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M13.3099 18.6881C12.5581 19.3396 11.4419 19.3396 10.6901 18.6881L5.87088 14.5114C4.47179 13.2988 5.32933 11 7.18074 11L9.00001 11V3C9.00001 1.89543 9.89544 1 11 1L13 1C14.1046 1 15 1.89543 15 3L15 11H16.8193C18.6707 11 19.5282 13.2988 18.1291 14.5114L13.3099 18.6881ZM11.3451 16.6091C11.7209 16.9348 12.2791 16.9348 12.6549 16.6091L16.8193 13H14.5C13.6716 13 13 12.3284 13 11.5V3L11 3V11.5C11 12.3284 10.3284 13 9.50001 13L7.18074 13L11.3451 16.6091Z" fill="#FFF"/>
                                                </svg>
                                                Download file
                                            </button>}
                                        </div>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>

                            <div className="fileField form-field wind_required">
                                <div className="file-validation-wrap">
                                    <div className="file-field-wrap">
                                        <span className="fieldTitle">File Duration</span>
                                        <label htmlFor={`windVideoDuration_${locale}`}></label>
                                        <FunctionField label="" source="wind_down.file_duration" render={() => {
                                            let duration;
                                            if (localizationData[locale].wind_down.file_duration) duration = getFileDurationFromTimestamp(localizationData[locale].wind_down.file_duration);

                                            return (
                                                <input
                                                    id={`windVideoDuration_${locale}`}
                                                    className="durationInput"
                                                    name={`wind_down[file_duration].en`}
                                                    value={windDownFileDuration ? windDownFileDuration : duration }
                                                    placeholder={'00:00:00'}
                                                    readOnly={true}
                                                />
                                            );
                                        }}/>
                                        <input type="hidden" className={`windFileDuration_${locale}`} value={windDownFileDurationValue ? windDownFileDurationValue : localizationData[locale].wind_down.file_duration}/>
                                    </div>
                                </div>
                                <p style={{display: 'none'}}>Required</p>
                            </div>
                        </>
                    }

                    <TextInput
                        label="Description"
                        className="form-field description wind"
                        name="wind_down.description"
                        source="wind_down.description"
                        id={`windDescription_${locale}`}
                        defaultValue={localizationData[locale].wind_down.description}
                        onChange={(e) => {localizationData[locale].wind_down.description = e.target.value; onInputChange('.form-field.description.wind')}}
                        style={{marginBottom: '20px'}}
                        multiline
                        fullWidth
                        validate={required()}
                    />
                    <p className="required-text">Required</p>

                    <div className="form-field wind_image_required">
                        <span className="fieldTitle">Image</span>
                        <div>
                            <div className="imageWrap" style={{height: windDownImageSrc || localizationData[locale].wind_down.image_url ? '194px' : 'fit-content'}}>
                                <img
                                    className="image"
                                    id={`image_wind_down_preview_${locale}`}
                                    src={windDownImageSrc ? windDownImageSrc : localizationData[locale].wind_down.image_url}
                                    alt=""
                                />
                                <p style={{display: 'none'}}>Required</p>
                            </div>
                            <label className="imageLabel" htmlFor={`windImageFile_${locale}`}></label>
                            <input
                                type="hidden"
                                id={`windImage_${locale}`}
                                name="wind_down_image.en"
                                value={windDownImageSrc ? windDownImageSrc : localizationData[locale].wind_down.image_url}
                            />
                            <input
                                type="file"
                                id={`windImageFile_${locale}`}
                                className="fileInput"
                                name="wind_down_image.en"
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={(e) => uploadImage(e, 'windDown')}
                            />
                            <label htmlFor={`windImageFile_${locale}`} className="uploadButton">
                                {windDownImageSrc || localizationData[locale].wind_down.image_url ? 'Update a File' : 'Upload a File'}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="dailyDareActions">
                {localizationData[locale].id && localizationData[locale].daily_dare?.length !== 0 && localizationData[locale].wind_down?.length !== 0 &&
                    <LocalizationActions id={id} status={localizationData[locale].status} url={'daily-dares'} redirectUrl={'/daily-dares/general'} prefix={locale}/>
                }
                <DailyLocalizationActions id={id} request={localizationData[locale].id ? 'edit' : 'create'} localization={locale}/>
            </div>
        </SimpleForm>
    );
}