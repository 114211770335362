export const convertedDate = (UNIX_timestamp) => {
    const unix = new Date(UNIX_timestamp * 1000);
    const year = unix.getFullYear();
    const month = '0' + (unix.getMonth() + 1);
    const date = '0' + unix.getDate();
    return `${year.toString()}-${month.toString().slice(-2)}-${date.toString().slice(-2)}`;
}

export const convertedDateWithTime = (UNIX_timestamp) => {
    const unix = new Date(UNIX_timestamp * 1000);
    const year = unix.getFullYear();
    const month = '0' + (unix.getMonth() + 1);
    const date = '0' + unix.getDate();

    const hour = '0' + unix.getHours();
    const min = '0' + unix.getMinutes();
    const sec = '0' + unix.getSeconds();
    const dateFormat = `${year.toString()}-${month.toString().slice(-2)}-${date.toString().slice(-2)}`;
    return `${dateFormat}, ${hour.toString().slice(-2)}:${min.toString().slice(-2)}:${sec.toString().slice(-2)}`;
}

export const convertedDateWithTimeGMT = (UNIX_timestamp) => {
    const unix = new Date(UNIX_timestamp * 1000);
    const year = unix.getUTCFullYear();
    const month = '0' + (unix.getUTCMonth() + 1);
    const date = '0' + unix.getUTCDate();

    const hour = '0' + unix.getUTCHours();
    const min = '0' + unix.getUTCMinutes();
    const sec = '0' + unix.getUTCSeconds();
    const dateFormat = `${year.toString()}-${month.toString().slice(-2)}-${date.toString().slice(-2)}`;
    return `${dateFormat}, ${hour.toString().slice(-2)}:${min.toString().slice(-2)}:${sec.toString().slice(-2)}`;
}