import React from 'react';
import { Pagination, useListContext } from 'react-admin';

export const PaginationMod = (props) => {
    const { setPage } = useListContext();
    const handleChangePage = (event, newPage) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        setPage(newPage+1);
    };

    return <Pagination {...props} onPageChange={handleChangePage} />;
}

export default PaginationMod;
