import * as React from "react";
import {
    BooleanInput,
    Edit,
    email,
    FunctionField,
    Link,
    required,
    SimpleForm,
    TextInput,
    useGetRecordId, useNotify,
    useRedirect
} from "react-admin";
import {useState} from "react";
import GradientButton from "../../../Component/Layouts/Button/GradientButton";
import {httpEdit} from "../../../Api/api";
import setLoadStatus from "../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";

export const EditUser = () => {
    const recordId = useGetRecordId();
    const redirect = useRedirect();
    const notify = useNotify();
    const dispatch = useDispatch();
    const [formData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        academy_subscription: false,
    });

    const handleSubmit = () => {
        formData.academy_subscription = formData.academy_subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False;

        dispatch(setLoadStatus(true));
        httpEdit(`customers/${recordId}`, formData).then(() => {
            dispatch(setLoadStatus(false));
            redirect(`/customers/${recordId}/show`);
            notify(`User update`);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    }

    const Save = () => {
        return (
            <div className="customerActionToolbar edit-user-btns">
                <Link to={`/customers/${recordId}/show`} className="button bar-purple-button">Cancel</Link>
                <GradientButton label={'Save'} onClick={handleSubmit} />
            </div>
        );
    }

    return <div className="edit-customer">
        <h2 className="dailyTitle">Edit User Details</h2>
        <Edit>
            <SimpleForm toolbar={<Save/>}>
                <FunctionField label="" render={(record) => {
                    formData.first_name = record.first_name;
                    return <>
                        <TextInput label="First Name" name="first_name" source="first_name" fullWidth id="first_name"
                                   className="formFields first-name" onChange={(e) => formData.first_name = e.target.value} validate={required()} multiline/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <FunctionField label="" render={(record) => {
                    formData.last_name = record.last_name;
                    return <>
                        <TextInput label="Last Name" name="last_name" source="last_name" fullWidth id="last_name"
                                   className="formFields last-name" onChange={(e) => formData.last_name = e.target.value} validate={required()} multiline/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <FunctionField label="" render={(record) => {
                    formData.email = record.email;
                    return <>
                        <TextInput label="Email" name="email" source="email" fullWidth id="email"
                                   className="formFields email" onChange={(e) => formData.email = e.target.value} validate={[required(), email()]}/>
                        <p className="required-text">Required</p>
                    </>;
                }}/>
                <FunctionField label="" render={(record) => {
                    formData.academy_subscription = record.academy_subscription;
                    return <BooleanInput label="Dare Academy Subscription" name="academy_subscription" source="academy_subscription"
                                         className="booleanInput"
                                         format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                         parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                         onChange={(e) => {
                                             formData.academy_subscription = e.target.checked
                                         }}/>;
                }}/>
            </SimpleForm>
        </Edit>
    </div>
}