import {
    Edit,
    required,
    minValue,
    TextInput,
    BooleanInput,
    NumberInput,
    Link,
    FunctionField,
    SimpleForm,
    TabbedShowLayout,
    Tab, useNotify, useDataProvider, useRefresh
} from "react-admin";
import * as React from "react";
import {useState} from "react";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {Priorities} from "../../../Component/Layouts/PriorityBlock";
import {useParams} from "react-router-dom";
import CategoryLocalization from "./categoryLocalization";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";

export const EditCategory = () => {
    const {id} = useParams();
    const [products, setProducts] = useState([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const [formData] = useState({
        name: '',
        order: 0,
        description: '',
        subscription: false,
        main: false,
        onboarding: false
    });

    const Products = ({record}) => {
        if (record.products && record.products.length) {
            setProducts(record.products);
            return (<Priorities data={record.products} title={'Products'} url="/products/general" changeOrder={changeOrder}/>);
        } else {
            return (<></>)
        }
    }

    const changeOrder = (e, productId) => {
        const currentValue = e.target.value;
        const productOrder = products.find(item => item.id === productId);
        productOrder.order = Number(currentValue);
    }

    const editCreate = () => {
        if (!formData.name.length || formData.order <= 0) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }
            if (formData.order <= 0) {
                document.querySelector('.order').classList.add('error');
            }

            return;
        }
        const productOrder = products.find(item => item.order <= 0);
        if (productOrder) {
            notify(`Product Order must be bigger then 0!`, 'warning');
            return;
        }
        dispatch(setLoadStatus(true));
        dataProvider.update(`categories/general`, {id: `${id}`, data: {
                name: formData.name,
                order: formData.order,
                description: formData.description,
                subscription: formData.subscription ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                main: formData.main ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                onboarding: formData.onboarding ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
                products
            }}).then(() => {
            dispatch(setLoadStatus(false));
            refresh(true);
            notify(`Category update`);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                    <Link to="/categories/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    const orderChange = (e) => {
        const val = +e.target.value;
        if (val <= 0) {
            document.querySelector('.order').classList.add('error');
            return;
        }
        if (document.querySelector('.order').classList.contains('error')) {
            document.querySelector('.order').classList.remove('error');
        }
        formData.order = val;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit Category</h2>
            <Edit title="Category">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <FunctionField label="" render={(record) => {
                                formData.name = record.name;
                                return <>
                                    <TextInput className="formFields name" label="Name" source="name" fullWidth id="name" validate={required()} onChange={(e) => {
                                        nameChange(e)
                                    }} multiline/>
                                    <p className="required-text">Required</p>
                                </>;
                            }}/>
                            <FunctionField label="" render={(record) => {
                                formData.order = record.order;
                                return <>
                                    <NumberInput label="Order" name="order" source="order" min={1} className="numberInput order" fullWidth id="order" validate={[required(), minValue(1)]} onChange={(e) => {orderChange(e)}}/>
                                    <p className="required-text">Must be at least 1</p>
                                </>;
                            }}/>
                            <FunctionField label="" render={(record) => {
                                formData.description = record.description;
                                return <TextInput className="formFields" label="Description" name="description" source="description" id="description" multiline fullWidth onChange={(e) => {
                                    formData.description = e.target.value
                                }}/>;
                            }}/>
                            <FunctionField label="" render={(record) => {
                                return <Products source="products" record={record} />;
                            }}/>
                            <FunctionField label="" render={(record) => {
                                formData.subscription = record.subscription;
                                return <BooleanInput label="Subscription Required"
                                                     name="subscription" source="subscription"
                                                     className="booleanInput"
                                                     format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                     parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                     onChange={(e) => {
                                                         formData.subscription = e.target.checked
                                                     }}/>;
                            }}/>
                            <p className="booleanInputDescription">Categories Place</p>
                            <FunctionField label="" render={(record) => {
                                formData.main = record.main;
                                return <BooleanInput label="Main" name="main" source="main"
                                                     className="booleanInput"
                                                     format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                     parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                     onChange={(e) => {
                                                         formData.main = e.target.checked
                                                     }}/>;
                            }}/>
                            <FunctionField label="" render={(record) => {
                                formData.onboarding = record.onboarding;
                                return <BooleanInput label="Onboarding" name="onboarding" source="onboarding"
                                                     className="booleanInput"
                                                     format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                     parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                     onChange={(e) => {
                                                         formData.onboarding = e.target.checked
                                                     }}/>;
                            }}/>
                            <Save />
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <CategoryLocalization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <CategoryLocalization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <CategoryLocalization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}