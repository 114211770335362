import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {MenuItemLink} from "react-admin";

const MultilevelMenu = ({ title, routes, menuItems, onMenuClick }) => {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [activeMenu, setActiveMenu] = useState(false);
    const location = useLocation()

    useEffect(() => {
        if (routes.some((route) => location.pathname.includes(route))) {
            setToggleMenu(true)
            setActiveMenu(true)
        } else {
            setActiveMenu(false)
        }
    }, [location, routes])

    return <div className="multilevel-menu">
        <div className={`multilevel-menu__title ${activeMenu ? 'active' : ''}`}>
            <p title={'Webinars'} onClick={() => setToggleMenu(!toggleMenu)}>
                { title }
            </p>
            <span className={`multilevel-menu__icon ${toggleMenu ? 'active' : ''}`}>
                        <svg fill="rgba(0, 0, 0, 0.54)" height="16px" width="14px" version="1.1" id="Layer_1"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 330 330">
                            <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                            />
                        </svg>
                    </span>
        </div>
        <ul className={`${toggleMenu ? 'open' : ''} multilevel-menu__items`}>
            { menuItems.map((menuItem) => {
                return <li key={menuItem.pathname}>
                    <MenuItemLink
                        to={{pathname: menuItem.pathname}}
                        primaryText={menuItem.primaryText}
                        onClick={onMenuClick}
                        dense={menuItem.dense}
                    />
                </li>
            }) }
        </ul>
    </div>
}

export default MultilevelMenu