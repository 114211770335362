import {
    TextInput,
    required,
    SimpleForm,
    useCreate,
    useNotify,
    useDataProvider,
    Link,
    useRedirect, FunctionField
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {LocalizationActions} from "../../../Component/Layouts/LocalizationActions";
import {useParams} from "react-router-dom";
import setLoadStatus from "../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";

const LOCALIZATION_DATA = {
    description: '',
    id: null,
    image_url: '',
    locale: '',
    name: '',
    status: null
}

const ProductLocalization = () => {
    const { '*': locale, id } = useParams();
    const initialRef = useRef(true);
    const [localizationData, setLocalizationData] = useState({[locale]: LOCALIZATION_DATA});
    const [create] = useCreate();
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();
    const formData = {
        name: '',
        description: '',
        image: ''
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        dataProvider.getOne('products', {id: `${id}/${locale}`}).then((res) => {
            setLocalizationData({[locale]: LOCALIZATION_DATA});
            if (res.data.id !== 0) {
                setLocalizationData({[locale]: res.data});
            }
        });
    }, [dataProvider, id, locale])

    const productLocalizationCreate = () => {
        if (!formData.name.length) {
            document.querySelector('.name').classList.add('error');
            return;
        }
        dispatch(setLoadStatus(true));
        if (!localizationData[locale].id) {
            create(`products/${id}/${locale}`, { data: {
                    name: formData.name,
                    description: formData.description,
                    image: formData.image
                } }, {
                onSuccess: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(`Product localization ${locale} created`);
                    redirect(`/products/general/${id}`);
                },
                onError: (res) => {
                    dispatch(setLoadStatus(false));
                    notify(res.message, 'warning');
                }
            });
        }

        if (localizationData[locale].id) {
            dataProvider.update(`products`, {id: `${id}/${locale}`, data: {
                name: formData.name,
                description: formData.description,
                image: formData.image
                }}).then(() => {
                dispatch(setLoadStatus(false));
                    notify(`Product localization ${locale} update`);
            }).catch((res) => {
                notify(res.message, 'warning');
                dispatch(setLoadStatus(false));
            });
        }
    }

    const Save = ({...props}) => {
        return (
            <div className="dailyActionToolbar">
                {props.localizationId &&
                    <LocalizationActions id={id} status={props.localizationStatus} url={'products'} redirectUrl={'/products/general'} prefix={locale}/>
                }
                <Link to="/products/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={productLocalizationCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const ImageUploadField = () => {
        const [imageSrc, setImageSrc] = useState(null);

        const uploadImage = async (imageFile) => {
            if (!imageFile) {
                return;
            }

            const mimeType = imageFile.type;
            const mimetypes = ['image/png', 'image/jpeg', 'image/jpg'];

            if (!mimetypes.includes(mimeType)) {
                notify(`Image should be in (.png, .jpeg, .jpg) formats.`, 'warning');
                return;
            }

            formData.image = imageFile;

            const reader = new FileReader();

            reader.onload = () => {
                setImageSrc(reader.result);
            }

            reader.readAsDataURL(imageFile);
        }

        return <div className="form-field">
            <span className="fieldTitle">Icon</span>
            <div>
                <div className="imageWrap">
                        <img
                            className="image"
                            src={imageSrc ? imageSrc : formData.image}
                            alt=""
                        />
                    <p style={{display: 'none'}}>Required</p>
                </div>
                <label className="imageLabel" htmlFor="imageInput"></label>
                <input
                    type="file"
                    id="imageInput"
                    className="fileInput"
                    accept="image/png, image/jpeg, image/jpg"
                    onChange={(e) => uploadImage(e.currentTarget.files[0])}
                />
                <label htmlFor="imageInput" className="uploadButton">
                    {formData.image ? 'Update Image' : 'Choose an Image'}
                </label>
            </div>
        </div>;
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <SimpleForm record={localizationData[locale]} toolbar={<></>}>
            <FunctionField label="" render={(record) => {
                formData.name = record.name;
                return <>
                    <TextInput className="formFields name" label="Title" name="name"
                               source="formData.name" fullWidth id="name" validate={required()}
                               onChange={(e) => {
                                   nameChange(e)
                               }} multiline/>
                    <p className="required-text">Required</p>
                </>;
            }}/>
            <FunctionField label="" render={(record) => {
                formData.description = record.description;
                return <TextInput className="formFields" label="Description" name="description"
                                  source="formData.description" id="description" multiline fullWidth
                                  onChange={(e) => {
                                      formData.description = e.target.value
                                  }}/>;
            }}/>
            <FunctionField label="" render={(record) => {
                formData.image = record.image_url;
                return <ImageUploadField />;
            }}/>
            <Save localizationId={localizationData[locale].id} localizationStatus={localizationData[locale].status} />
        </SimpleForm>
    );
}

export default ProductLocalization;