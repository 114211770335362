import {FunctionField, required, SimpleForm, TextInput} from "react-admin";
import {Calendar} from "react-multi-date-picker";
import {convertedDate, convertedDateWithTime} from "../../../../Functions/ConvertedDate";
import * as React from "react";
import {DailyDareGeneralActions} from "./dailyDareGeneralActions";
import {useState} from "react";

export const GeneralForm = (props) => {
    const id = props.id;

    const [date, setDate] = useState('');

    const onDateChange = (e) => {
        if (e && e.value.unix) {
            const date = new Date(e.value.unix * 1000);
            setDate(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
        }
    }
    return (
        <SimpleForm toolbar={<DailyDareGeneralActions id={id}/>}>
            <div className="formFields">
                <TextInput label="Name" name="name" source="name" fullWidth id="name" validate={required()} multiline/>
                <TextInput label="Description" name="description" source="description" id="description" multiline
                           fullWidth/>
                <span className="showDateLabel">Show Date</span>
                <FunctionField source="show_date" render={(record) => {
                    return (
                        <>
                            <Calendar
                                value={record.show_date ? convertedDateWithTime(record.show_date) : null}
                                onPropsChange={(e) => {
                                    onDateChange(e)
                                }}
                                minDate="2000/01/01"
                                maxDate="3000/12/31"
                            />
                            <input type="hidden" id="show_date"
                                   value={record.show_date && !date ? convertedDate(record.show_date) : date}/>
                        </>
                    );
                }}/>
            </div>
        </SimpleForm>
    );
}