const initialState = { isLoading: false };

const GET_LOAD_STATUS = 'GET_LOAD_STATUS';

export function LoaderReducer(state = initialState, action) {
    switch (action.type) {
        case GET_LOAD_STATUS:
            return action.payload;
        default:
            return state
    }
}

export const setLoadStatus = (isLoadingStatus) => ({
    type: GET_LOAD_STATUS,
    payload: { isLoading: isLoadingStatus },
});

export default setLoadStatus;