import {Datagrid, Filter, FunctionField, TextField, TextInput, TopToolbar} from "react-admin";
import React from "react";
import CrudMod from "../../../Component/Layouts/Button/CrudMod";
import CreateGradientButtonMod from "../../../Component/Layouts/Button/CreateGradientButtonMod";
import ListMod from "../../../Component/Redesign/ListMod";
import {convertedDate} from "../../../Functions/ConvertedDate";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput variant="standard" label="Search" source="search" alwaysOn/>
    </Filter>
);

const SubscriptionField = ({record}) => {
    if (!record.subscription) return 'Not Required';
    return (
        <span>{record.subscription === 1 && 'Required'}</span>
    );
}

const PublishedField = ({record}) => {
    if (!record.published) return 'No';
    return (
        <span>{record.published === 1 && 'Yes'}</span>
    );
}

const LocalsField = ({record}) => {
    if (!record.locales || !record.locales.length) {
        return '';
    }

    const lastElement = record.locales.length - 1;
    return record.locales.map((item, i) => (
        <span key={i}>{item.locale}{i === lastElement ? '' : ', '}</span>
    ))
};

export const WebinarHistories = (props) => {
    const ListActions = ({className}) => {
        return (<TopToolbar className={className}>
            <div className="actionsWrap">
                <div className="addCategoryBtnWrap">
                    <CreateGradientButtonMod label={'Add New Webinar'}/>
                </div>
            </div>
        </TopToolbar>);
    };

    return (
        <div className="customTableWrap">
            <ListMod {...props} title="Webinar Histories" filters={<Filters/>} actions={<ListActions/>} resource="webinar-histories/general"
                     sort={{field: 'order', order: 'ASC'}}>
                <Datagrid bulkActionButtons={false} className="customTable files-table">
                    <FunctionField label="Show Date" source="show_date" sortBy="show_date" render={(record) => {
                        return (convertedDate(record.show_date));
                    }}/>
                    <FunctionField label="Name" source="name" sortBy="name" render={(record) => {
                        return <span title={record.name}>{record.name}</span>;
                    }}/>
                    <FunctionField label='Description' source="description" sortBy="description" render={(record) => {
                        return (
                            <span title={record.description}>{record.description}</span>
                        );
                    }}/>
                    <TextField label='Order' source="order" sortBy="order"/>
                    <FunctionField label="Visible" source="published" sortBy="published" render={(record) => {
                        return <PublishedField record={record}/>;
                    }}/>
                    <FunctionField label='Subscription' source="subscription" sortBy="subscription"
                                   render={(record) => {
                                       return <SubscriptionField record={record}/>
                                   }}/>
                    <FunctionField label="Localization" source="locales" sortBy="locales" render={(record) => {
                        return <LocalsField record={record}/>;
                    }}/>
                    <FunctionField label="" source="actions" render={(record) => {
                        return <CrudMod edit="true" del="true" id={record.id} record={record}/>;
                    }}/>
                </Datagrid>
            </ListMod>
        </div>
    )
}

export default WebinarHistories;
