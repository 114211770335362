import {
    Edit,
    required,
    TextInput,
    BooleanInput,
    Link,
    SimpleForm,
    TabbedShowLayout,
    Tab,
    useNotify,
    useDataProvider,
    FunctionField,
    useRefresh
} from "react-admin";
import * as React from "react";
import {useEffect, useRef, useState} from "react";
import AsyncSelect from "react-select/async";
import {httpGet, httpGetOneWithParams} from "../../../Api/api";
import {EBooleanStatuses, EBooleanStatusesNumber} from "../../../Const/Enums";
import {useParams} from "react-router-dom";
import FileLocalization from "./fileLocalization";
import {useDispatch} from "react-redux";
import setLoadStatus from "../../../Store/LoaderReducer";

export const EditFile = () => {
    const {id} = useParams();
    const [productsList, setProductsList] = useState([]);
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const dispatch = useDispatch();

    const initialRef = useRef(true);
    const [formData] = useState({
        name: '',
        description: '',
        published: false,
        products: []
    });

    useEffect(() => {
        if (!initialRef.current) {
            return;
        }
        initialRef.current = false;

        httpGet('files/general/product-list').then(res => {
            if (res.data.length) {
                setProductsList(res.data)
            }
        });

    }, []);

    const editCreate = () => {
        let newProducts = formData.products.map((item) => (item.value ? item.value : item));
        const selectedProducts = !newProducts.length ? [] : formData.products.map((item) => (item.value ? item.value : item));
        newProducts = newProducts.length ? newProducts : selectedProducts;

        if (!formData.name.length || !newProducts.length) {
            if (!formData.name.length) {
                document.querySelector('.name').classList.add('error');
            }
            if (!newProducts.length) {
                notify('Please, choose product.', {type: 'warning'});
                document.querySelector('.async-select').classList.add('error');
            }
            return;
        }

        dispatch(setLoadStatus(true));
        dataProvider.update(`files/general`, {id: `${id}`, data: {
                name: formData.name,
                description: formData.description,
                products: newProducts,
                published: formData.published ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False,
            }}).then(() => {
            dispatch(setLoadStatus(false));
            notify(`File update`);
            refresh(true);
        }).catch((err) => {
            notify(err.message, {type: 'error'});
            dispatch(setLoadStatus(false));
        });
    };

    const Save = () => {
        return (
            <div className="dailyActionToolbar">
                    <Link to="/files/general" className="button bar-purple-button">Cancel</Link>
                <button
                    onClick={editCreate}
                    className="button bar-gradient-button"
                    type="button"
                >
                    <span>Save</span>
                </button>
            </div>
        );
    }

    const loadOptions = (
        inputValue
    ) => {
        return new Promise((resolve, reject) => {
            return httpGetOneWithParams('files/general/product-list', {label: inputValue}).then((res) => {
                if (res.data.length) {
                    resolve(res.data)
                } else {
                    resolve([])
                }
            }, reject)

        })
    };

    const selectProducts = (e) => {
        if (document.querySelector('.async-select').classList.contains('error')) {
            document.querySelector('.async-select').classList.remove('error');
        }

        const selectedProducts = e.map((item) => (item.value));
        formData.products = selectedProducts;
    }

    const nameChange = (e) => {
        if (document.querySelector('.name').classList.contains('error')) {
            document.querySelector('.name').classList.remove('error');
        }
        formData.name = e.target.value;
    }

    return (
        <>
            <h2 className="dailyTitle">Edit File</h2>
            <Edit title="File">
                <TabbedShowLayout className="tabbedForm">
                    <Tab label="General">
                        <SimpleForm toolbar={<></>}>
                            <div className="tabbedFormWrap">
                                <FunctionField label="" render={(record) => {
                                    formData.name = record.name;
                                    return <>
                                        <TextInput className="formFields name" label="Name" name="name"
                                                   source="formData.name" fullWidth id="name" validate={required()}
                                                   onChange={(e) => {
                                                       nameChange(e)
                                                   }} multiline/>
                                        <p className="required-text">Required</p>
                                    </>;
                                }}/>
                                <FunctionField label="" render={(record) => {
                                    formData.description = record.description;
                                    return <TextInput className="formFields" label="Description" name="description" source="formData.description" id="description" multiline fullWidth onChange={(e) => {
                                        formData.description = e.target.value
                                    }} />;
                                }}/>
                                <FunctionField label="" source="products" render={(record) => {
                                    if (!record.products.length) return <div className="async-select-wrap">
                                        <AsyncSelect placeholder="Product" className="async-select" isMulti source="products" defaultOptions={productsList} loadOptions={loadOptions} onChange={(e) => {selectProducts(e)}}/>
                                        <p className="required-text">Required</p>
                                    </div>

                                    formData.products = record.products;
                                    const selectedProducts = record.products.reduce((acc, curr) => {
                                        if (curr.value) {
                                            acc.push({value: curr.value, label: curr.label})
                                        }
                                        return acc;
                                    }, []);
                                    return selectedProducts.length && <div className="async-select-wrap">
                                        <AsyncSelect placeholder="Product" className="async-select" isMulti source="products" defaultValue={selectedProducts} defaultOptions={productsList} loadOptions={loadOptions} optionText="label" optionValue="value" onChange={(e) => {selectProducts(e)}}/>
                                        <p className="required-text">Required</p>
                                    </div>
                                }} fullWidth />
                                <FunctionField label="" render={(record) => {
                                    formData.published = record.published;
                                    return <BooleanInput label="Visible"
                                                         name="published"
                                                         source="published"
                                                         className="booleanInput"
                                                         format={(value) => (value ? EBooleanStatuses.True : EBooleanStatuses.False)}
                                                         parse={(value) => (value ? EBooleanStatusesNumber.True : EBooleanStatusesNumber.False)}
                                                         onChange={(e) => {
                                                             formData.published = e.target.checked
                                                         }}/>;
                                }}/>
                            </div>
                            <Save />
                        </SimpleForm>
                    </Tab>
                    <Tab label="EN" path="en">
                        <FileLocalization />
                    </Tab>
                    <Tab label="PT" path="pt">
                        <FileLocalization />
                    </Tab>
                    <Tab label="DE" path="de">
                        <FileLocalization />
                    </Tab>
                </TabbedShowLayout>
            </Edit>
        </>
    );
}