import PurpleBorderButton from "../../../Component/Layouts/Button/PurpleBorderButton";
import {useState} from "react";
import Modal from "../../../Component/Layouts/Modal/Modal";
import {
    required,
    SelectInput,
    SimpleForm,
    TextInput,
    FunctionField,
    useNotify,
    useDataProvider, useRefresh
} from "react-admin";
import PurpleButton from "../../../Component/Layouts/Button/PurpleButton";
import RedBorderButton from "../../../Component/Layouts/Button/RedBorderButton";
import ModalButtons from "../../../Component/Layouts/Button/ModalButtons";
import {convertedDateWithTime, convertedDateWithTimeGMT} from "../../../Functions/ConvertedDate";
import DatePicker from "react-multi-date-picker";
import setLoadStatus from "../../../Store/LoaderReducer";
import {useDispatch} from "react-redux";
import {httpEdit} from "../../../Api/api";
import {Subscriptions, SubscriptionSources} from "../../../Const/Enums";

export const Subscription = (props) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const dispatch = useDispatch();

    const manualSubscription = props?.manual_subscription;
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        setShowModal(true);
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const defaultDate = () => {
        const unix = new Date();
        const year = unix.getUTCFullYear();
        const month = '0' + (unix.getUTCMonth() + 1);
        const date = '0' + unix.getUTCDate();

        const hour = '0' + unix.getHours();
        const min = '0' + unix.getMinutes();
        const sec = '0' + unix.getSeconds();

        const dateFormat = `${year.toString()}-${month.toString().slice(-2)}-${date.toString().slice(-2)}`;

        return `${dateFormat}, ${hour.toString().slice(-2)}:${min.toString().slice(-2)}:${sec.toString().slice(-2)}`;
    }

    const setDate = (e) => {
        const unix = new Date(e.unix * 1000);

        const year = unix.getFullYear();
        const month = '0' + (unix.getMonth() + 1);
        const date = '0' + unix.getDate();

        const dateFormat = `${year.toString()}-${month.toString().slice(-2)}-${date.toString().slice(-2)}`;
        const input = document.querySelector('.custom-datepicker-field input');
        input.value = `${dateFormat}, 23:59:59`;
        const expireAtField = document.querySelector('.custom-datepicker-wrap');
        expireAtField.classList.remove('error');
    }

    const sendSubscription = (manualSubscriptionId) => {
        const startAt = document.querySelector('.start_at-filed input').value;
        const localization = document.querySelector('.localization-field input').value;
        const source = document.querySelector('.source-field input').value;
        const expireAt = document.querySelector('.custom-datepicker-field input').value;

        const localizationField = document.querySelector('.localization-field-wrap');
        const sourceField = document.querySelector('.source-field-wrap');
        const expireAtField = document.querySelector('.custom-datepicker-wrap');

        const unix = new Date();
        const year = unix.getUTCFullYear();
        const month = '0' + (unix.getUTCMonth() + 1);
        const date = '0' + unix.getUTCDate();

        const hour = '0' + unix.getUTCHours();
        const min = '0' + unix.getUTCMinutes();
        const sec = '0' + unix.getUTCSeconds();

        const dateFormat = `${year.toString()}-${month.toString().slice(-2)}-${date.toString().slice(-2)}`;

        if (!startAt || !localization || !source || !expireAt || expireAt === 'YYYY-MM-DD, HH:MM:SS') {
            if (!localization) {
                localizationField.classList.add('error');
            }

            if (!source) {
                sourceField.classList.add('error');
            }

            if (!expireAt || expireAt === 'YYYY-MM-DD, HH:MM:SS') {
                expireAtField.classList.add('error');
            }

            return;
        }

        dispatch(setLoadStatus(true));

        const formData = {
            start_at: `${dateFormat}, ${hour.toString().slice(-2)}:${min.toString().slice(-2)}:${sec.toString().slice(-2)}`,
            localization,
            source,
            expire_at: expireAt
        }

        if (!manualSubscriptionId) {
            dispatch(setLoadStatus(true));
            dataProvider.create(`customer/${props.id}/subscriptions`, {data: formData}).then(() => {
                notify(`Subscription created`);
                refresh();
                dispatch(setLoadStatus(false));
                handleCloseModal();
            }).catch((err) => {
                notify(err.message, {type: 'error'});
                dispatch(setLoadStatus(false));
            });
        }

        if (manualSubscriptionId) {
            dispatch(setLoadStatus(true));
            httpEdit(`customer/${props.id}/subscriptions`, formData).then(() => {
                notify(`Subscription updated`);
                refresh();
                dispatch(setLoadStatus(false));
                handleCloseModal();
            }).catch((err) => {
                notify(err.message, {type: 'error'});
                dispatch(setLoadStatus(false));
            });
        }
    }

    const setLocalization = () => {
        const localizationField = document.querySelector('.localization-field-wrap');
        localizationField.classList.remove('error');
    }

    const setSource = () => {
        const sourceField = document.querySelector('.source-field-wrap');
        sourceField.classList.remove('error');
    }

    const deleteSubscription = (manualSubscriptionId) => {
        if (manualSubscriptionId) {
            dataProvider.delete('customer', {id: `${manualSubscriptionId}/subscriptions/delete`}).then(() => {
                notify('Subscription deleted');
                handleCloseModal();
                refresh();
            });
        }
    }

    const SubscriptionForm = () => {
        return (
            <SimpleForm record={manualSubscription} toolbar={<></>}>
                <TextInput className="start_at-filed"
                           name="manualSubscription.start_at"
                           label="Date Start"
                           source="manualSubscription.start_at"
                           defaultValue={manualSubscription?.start_at
                               ? convertedDateWithTime(manualSubscription?.start_at)
                               : defaultDate
                           }
                           fullWidth
                           disabled={true}
                           readOnly={true}
                           validate={required()}
                />
                <div className="localization-field-wrap">
                    <SelectInput
                        className="localization-field"
                        name="localization"
                        label="Localization"
                        source="localization"
                        fullWidth
                        choices={Subscriptions}
                        validate={required()}
                        onChange={() => setLocalization()}
                        defaultValue={'all'}
                        disabled={true}
                        readOnly={true}
                    />
                    <p className="required-text">Required</p>
                </div>
                <div className="source-field-wrap">
                    <SelectInput
                        className="source-field"
                        name="source"
                        label="Subscription Source"
                        source="source"
                        fullWidth
                        choices={SubscriptionSources}
                        validate={required()}
                        onChange={() => setSource()}
                        defaultValue={1}
                    />
                    <p className="required-text">Required</p>
                </div>
                <div className="custom-datepicker-wrap">
                    <FunctionField render={() => {
                        return <>
                            <div className="custom-datepicker">
                                <TextInput
                                    className="custom-datepicker-field"
                                    name="manualSubscription.expire_at"
                                    label="Date Expire At"
                                    source="manualSubscription.expire_at"
                                    defaultValue={manualSubscription?.expire_at
                                        ? convertedDateWithTimeGMT(manualSubscription.expire_at)
                                        : 'YYYY-MM-DD, HH:MM:SS'
                                    }
                                    fullWidth
                                    disabled={true}
                                    readOnly={true}
                                    validate={required()}
                                />
                                <label htmlFor="picker" className="picker">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
                                        <path fill="#000000" fillRule="evenodd"
                                              d="M61,154.006845 C61,153.45078 61.4499488,153 62.0068455,153 L73.9931545,153 C74.5492199,153 75,153.449949 75,154.006845 L75,165.993155 C75,166.54922 74.5500512,167 73.9931545,167 L62.0068455,167 C61.4507801,167 61,166.550051 61,165.993155 L61,154.006845 Z M62,157 L74,157 L74,166 L62,166 L62,157 Z M64,152.5 C64,152.223858 64.214035,152 64.5046844,152 L65.4953156,152 C65.7740451,152 66,152.231934 66,152.5 L66,153 L64,153 L64,152.5 Z M70,152.5 C70,152.223858 70.214035,152 70.5046844,152 L71.4953156,152 C71.7740451,152 72,152.231934 72,152.5 L72,153 L70,153 L70,152.5 Z"
                                              transform="translate(-61 -152)"/>
                                    </svg>
                                </label>
                                <DatePicker minDate={new Date().setDate(new Date().getDate()+1)} type="date" id="picker" onChange={(e) => setDate(e)}/>
                            </div>
                        </>
                    }
                    }></FunctionField>
                    <p className="required-text">Required</p>
                </div>
                <ModalButtons onCancel={handleCloseModal} removeSubscription={
                    manualSubscription?.id && <RedBorderButton label={'Restrict Access'} onClick={() => {
                        deleteSubscription(props?.id)
                    }} />
                } onSubmit={() => {
                    sendSubscription(manualSubscription?.id)
                    }}/>
            </SimpleForm>
        );
    }

    return (
       <>
           {!manualSubscription
               ? <PurpleBorderButton label={'Grant Access'} width={'151px'} onClick={() => handleOpenModal()}/>
               : <PurpleButton label={'Edit Subscription'} width={'167px'} margin={'0'} onClick={() => handleOpenModal()} />}
           {showModal && <Modal onClose={handleCloseModal} title={manualSubscription?.id ? 'Edit Subscription' : 'Create Subscription'} content={<SubscriptionForm />}></Modal>}
       </>
    )
}